import React from 'react';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {Link} from "react-router-dom";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import FastfoodOutlinedIcon from "@material-ui/icons/FastfoodOutlined";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import BottomNavigation from "@material-ui/core/BottomNavigation";

export default function BottomBar() {
	const [value, setValue] = React.useState(0);//TODO По умолчанию поставил главную, если на эти страницы будут переходы не с этой меню, нужно убдет настроить
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<BottomNavigation
			value={value}
			onChange={handleChange}
			showLabels
		>
			<BottomNavigationAction component={Link} to="/" label="Главная" icon={<HomeOutlinedIcon/>}/>
			<BottomNavigationAction component={Link} to="/products/" label="Продукты" icon={<FastfoodOutlinedIcon/>}/>
			<BottomNavigationAction component={Link} to="/diary/" label="Дневник" icon={<BookOutlinedIcon/>}/>
			<BottomNavigationAction component={Link} to="/account/" label="Аккаунт" icon={<AccountCircleOutlinedIcon/>}/>
		</BottomNavigation>
	);
}