import React, {useState} from "react";
import TopBar from "../../components/common/TopBar/TopBar";
import CalendarDialog from "../../components/common/CalendarDialog/CalendarDialog";
import WeekCalendar from "../../components/meal/WeekCalendar";
import ActionTile from "../../components/common/ActionTile/ActionTile";
import WeightForm from "../../components/diary/WeightForm";
import SportForm from "../../components/diary/SportForm";
import CommentForm from "../../components/diary/CommentForm";
import VolumeForm from "../../components/diary/VolumeForm";
import Grid from '@material-ui/core/Grid';
import Counters from "../../components/diary/Counters";

export default function DiaryPage(props) {
	let [curDate, setCurDate] = useState(new Date().yyyymmdd());
	const [WeightShowDialog, setWeightShowDialog] = useState(false);
	const handleOpenWeight = () => setWeightShowDialog(true)
	const handleCloseWeight = () => {
		setTimeout(()=> setWeightShowDialog(false),300);
	};

	const [SportShowDialog, setSportShowDialog] = useState(false);
	const handleOpenSport = () => setSportShowDialog(true)
	const handleCloseSport = () => {
		setTimeout(()=> setSportShowDialog(false),300);
	};

	const [CommentShowDialog, setCommentShowDialog] = useState(false);
	const handleOpenComment = () => setCommentShowDialog(true)
	const handleCloseComment = () => {
		setTimeout(()=> setCommentShowDialog(false),300);
	};

	const [volumeShowDialog, setVolumeShowDialog] = useState(false)
	const handleOpenVolume = () => setVolumeShowDialog(true)
	const handleCloseVolume = () => {
		setTimeout(()=> setVolumeShowDialog(false),300);
	};

	const [countersShowDialog, setCountersShowDialog] = useState(false)
	const handleOpenCounters = () => setCountersShowDialog(true)
	const handleCloseCounters = () => {
		setTimeout(()=> setCountersShowDialog(false),300);
	};

	const weightPars = {
		title: "Вес",
		icon: "weight",
		value: "56",
		unit: "кг",
		status: "done",
	}

	const waterPars = {
		title: "Вода",
		icon: "water",
		amount: "1500",
		unit: "мл",
		status: "done",
		link: "/water/",
	}

	const volumePars = {
		title: "Объемы",
		icon: "volume",
		status: "empty",
	}

	const mealPars = {
		title: "Еда",
		icon: "meal",
		status: "done",
		link: "/meal/"
	}
	const sportPars = {
		title: "Спорт",
		icon: "sport",
		status: "done",
		amount: "450",
		unit: "ккал"
	}

	const countersPars = {
		title: "Счетчики",
		icon: "calc",
		status: "empty",
	}

	const commentPars = {
		title: "Коммент",
		icon: "comment",
		value: "Отличный день. Покушал и позанимался спортом!",
		status: "empty",
	}

	return (
		<div className="app-page">
			<TopBar title="Дневник">
				<CalendarDialog date={curDate} onSelect={setCurDate}/>
			</TopBar>
			<div className="app-content">
				<WeekCalendar date={curDate} onSelect={setCurDate} startFromMonday={false}/>
				<Grid className="mt-16" container spacing={2}>
					<Grid item xs={4}>
						<ActionTile icon={waterPars.icon} title={waterPars.title} text={[waterPars.amount, waterPars.unit]} status={waterPars.status} link={waterPars.link} />
					</Grid>
					<Grid item xs={4}>
						<ActionTile icon={weightPars.icon} title={weightPars.title} text={[weightPars.value, weightPars.unit]} status={weightPars.status} onOpen={handleOpenWeight} />
					</Grid>
					<Grid item xs={4}>
						<ActionTile icon={volumePars.icon} title={volumePars.title} status={volumePars.status} onOpen={handleOpenVolume} />
					</Grid>
					<Grid item xs={4}>
						<ActionTile icon={mealPars.icon} title={mealPars.title} status={mealPars.status} link={mealPars.link} />
					</Grid>
					<Grid item xs={4}>
						<ActionTile icon={sportPars.icon} title={sportPars.title} text={[sportPars.amount, sportPars.unit]} status={sportPars.status} onOpen={handleOpenSport} />
					</Grid>
					<Grid item xs={4}>
						<ActionTile icon={countersPars.icon} title={countersPars.title} status={countersPars.status} onOpen={handleOpenCounters} />
					</Grid>
					<Grid item xs={4}>
						<ActionTile icon={commentPars.icon} title={commentPars.title} status={commentPars.status} onOpen={handleOpenComment} />
					</Grid>
				</Grid>
				{WeightShowDialog && (
					<WeightForm value={weightPars.value} onClose={handleCloseWeight} />
				)}
				{SportShowDialog && (
					<SportForm value={sportPars.amount} onClose={handleCloseSport} />
				)}
				{CommentShowDialog && (
					<CommentForm value={commentPars.value} onClose={handleCloseComment} />
				)}
				{volumeShowDialog && (
					<VolumeForm onClose={handleCloseVolume}  />
				)}
				{countersShowDialog && (
					<Counters onClose={handleCloseCounters}  />
				)}
			</div>
		</div>
	);
}
