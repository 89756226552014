import React from "react";

export function FuiLoader() {
    return (
        <div className="loader">
            <div className="loader__box">
                <div className="loader__spinner"></div>
            </div>
        </div>
    )
}
