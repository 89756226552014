import React from 'react'
import Alert from "@material-ui/lab/Alert";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

export const MealContentList = props => {
	return props.items && props.items.length > 0 ? (
		<List className="fui-list-bordered fui-list-action-left">
				{props.items.map(mealContent => (
					<ListItem key={mealContent.id} button onClick={() => {
						props.onEdit(mealContent)
					}}>
						<ListItemText
							primary={
								<React.Fragment>
								<span className="fui-list-name">
									<span className="fui-list-name__value">
										{mealContent.name}
									</span>
								</span>
								</React.Fragment>
							}
							secondary={
								<React.Fragment>
							<span className="food-amount">
								{mealContent.amount} гр
							</span>
									<span className="food-data">
								<span className="food-data__left">
									<span className="food-data__item">Б: {(mealContent.b*mealContent.amount*0.01).toFixed(1)}</span>
									<span className="food-data__item">Ж: {(mealContent.z*mealContent.amount*0.01).toFixed(1)}</span>
									<span className="food-data__item">У: {(mealContent.u*mealContent.amount*0.01).toFixed(1)}</span>
								</span>
								<span className="food-data__right">
									<span className="food-data__item">{(mealContent.k*mealContent.amount*0.01).toFixed(0)} ккал</span>
								</span>
							</span>
								</React.Fragment>
							}
						/>
						<ListItemSecondaryAction>
							<Checkbox
								checked={!!mealContent.status}
								onChange={() => props.onSelect(mealContent.id, !mealContent.status)}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
	) : (
		<Alert severity="info">Нет данных</Alert>
	)
}