import React from "react"
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import AppVersion from './components/common/AppVersion/AppVersion'
import ClearLayout from './components/common/layouts/ClearLayout'
import MainLayout from './components/common/layouts/MainLayout'
import TabsLayout from './components/common/layouts/TabsLayout'
import CalendarTotalsLayout from './components/common/layouts/CalendarTotalsLayout'
import TotalsLayout from './components/common/layouts/TotalsLayout'
import HomePage from './pages/home';
import ProductsPage from './pages/products/products-page'
import DiaryPage from './pages/diary'
import AccountPage from './pages/account'
import CorridorPage from './pages/corridor'
import MealPage from './pages/meal'
import MealPartPage from './pages/meal/mealpart'
import WaterPage from './pages/water'
import {PrivateRoute, Login, fakeAuth} from './components/common/Auth/Auth'
import { ToastContainer, Flip } from 'react-toastify';
import ScrollToTop from './components/common/ScrollToTop/ScrollToTop'
import SignUpPage from "./components/user/SignUp";

export default function App() {
    fakeAuth.init();
    return (
        <Router>
            <ScrollToTop />
            <AppVersion />
            <Route path={['/login/', "/water/"]}>
                <ClearLayout>
                    <Route path="/login/" component={Login}/>
                    <PrivateRoute path='/water/' component={WaterPage}/>
                </ClearLayout>
            </Route>
            <Route path={['/user/']}>
                <ClearLayout>
                    <Route path="/user/" component={SignUpPage}/>
                </ClearLayout>
            </Route>
            <PrivateRoute exact path={["/", "/products/", "/diary/", "/account/" ]}>
                <MainLayout>
                    <PrivateRoute exact path='/' component={HomePage}/>
                    <PrivateRoute path='/products/' component={ProductsPage}/>
                    <PrivateRoute path='/diary/' component={DiaryPage}/>
                    <PrivateRoute path='/account/' component={AccountPage}/>
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute exact path={["/corridor/"]}>
                <TabsLayout>
                    <PrivateRoute path='/corridor/' component={CorridorPage}/>
                </TabsLayout>
            </PrivateRoute>
            <PrivateRoute exact path={['/meal/','/meal/:date/']}>
                <CalendarTotalsLayout>
                    <PrivateRoute exact path='/meal/' component={MealPage}/>
                    <PrivateRoute exact path='/meal/:date/' component={MealPage}/>
                </CalendarTotalsLayout>
            </PrivateRoute>
            <PrivateRoute path={["/meal/:date/:eating"]}>
                <TotalsLayout>
                    <PrivateRoute exact path='/meal/:date/:eating' component={MealPartPage}/>
                </TotalsLayout>
            </PrivateRoute>
            <ToastContainer
                position="bottom-center"
                autoClose={1100}
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                rtl={false}
                draggable
                transition={Flip}
            />
        </Router>
    )
}
