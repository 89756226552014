import React from "react";

export default function AppVersion() {
    return (
        <div className="app-version">
            <div className="app-version__value">
                {process.env.REACT_APP_VERSION}
            </div>
        </div>
    )
}