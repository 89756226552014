import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {CorridorForm} from "../../components/corridor/CorridorForm"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import CorridorFormCalc from "../../components/corridor/CorridorFormCalc"
import UndoOutlinedIcon from "@material-ui/icons/UndoOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {Link as RouterLink} from "react-router-dom";
import {FuiLoader} from "../../components/common";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div>
					{children}
				</div>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
}));

export default function CorridorPage() {
	const classes = useStyles();
	let [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(0);
	const [showCalc, setShowCalc] = useState(false);
	const [showCalcButton, setShowCalcButton] = useState(false);

	const [userCorParams, setUserCorParams] = useState(false);
	const initialFormState = {
		id: null,
		k1: '', k2: '', b1: '', b2: '', z1: '', z2: '', u1: '', u2: '', w: '',
		day: 0
	}
	// значение "текущий пользователь на редактировании" + функция установки этого значения
	const [currentCorridorItem, setCurrentCorridorItem] = useState(initialFormState)



	const [weekSingleMode, setWeekSingleMode] = useState(false);
	const [weekChanged, setWeekChanged] = useState()
	const [weekLoaded, setWeekLoaded] = useState(false)
	const [dc0, sdc0] = useState(initialFormState)
	const [dc1, sdc1] = useState({...initialFormState,day:1})
	const [dc2, sdc2] = useState({...initialFormState,day:2})
	const [dc3, sdc3] = useState({...initialFormState,day:3})
	const [dc4, sdc4] = useState({...initialFormState,day:4})
	const [dc5, sdc5] = useState({...initialFormState,day:5})
	const [dc6, sdc6] = useState({...initialFormState,day:6})
	const [dc7, sdc7] = useState({...initialFormState,day:7})


	const handleChange = (event, newValue) => {
		console.log('day save',value,'=>',newValue,currentCorridorItem);
		//console.log('day1 save',newValue,dc1);
		//console.log('day2 save',newValue,dc2);
		//switch (value)
		/*
		if (value===0) {			sdc0(currentCorridorItem)		}
		if (value===1) {			sdc1(currentCorridorItem)		}
		if (value===2) {			sdc2(currentCorridorItem)		}*/
		setValue(newValue);
	};
	const handleChangeItem = (data) => {
		setWeekChanged(true);
	}
	const handleUpdateItem = (updatedData) => {
		console.log('handleChangeItem');
		setCurrentCorridorItem(updatedData)//use in tabs-mode
		setDayValue(updatedData);
	}
	function getDayValue(day) {
		let r;
		switch (day) {
			case 0: r = dc0; break;
			case 1: r = dc1; break;
			case 2: r = dc2; break;
			case 3: r = dc3; break;
			case 4: r = dc4; break;
			case 5: r = dc5; break;
			case 6: r = dc6; break;
			case 7: r = dc7; break;
		}
		return r;
	}
	function setDayValue(data) {
		switch (Number(data.day)) {
			case 0: sdc0(data); break;
			case 1: sdc1(data); break;
			case 2: sdc2(data); break;
			case 3: sdc3(data); break;
			case 4: sdc4(data); break;
			case 5: sdc5(data); break;
			case 6: sdc6(data); break;
			case 7: sdc7(data); break;
		}
	}

	useEffect(()=>{
		if (weekSingleMode && value>0) {
			setValue(0);
			setCurrentCorridorItem(getDayValue(0));
		}
	},[weekSingleMode]);


	//TODO get from server for this user
	useEffect(()=>{
		setWeekChanged(false);
		//TODO хавк - настроить чтобы значения direction,gender передавались в форму расчета (сейчас не работает)
		setUserCorParams({
			id: null,
			gender: 'M',
			age: '20',
			weight: '80',
			height: '180',

			direction: 'up',
			speed: 1,
			activity: 1,
			system: 1
		})
		//TODO load from server
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'corridor';
		setLoading(true);
		fetch(url)
			.then((res) => res.json())
			.then((json) => {
				json.corridors.map(cor=>{
					setDayValue(cor);
				});
				console.log('loaded all days');
				if (!json.corridors.length) {
					setShowCalcButton(true);
				}
				setWeekLoaded(true);
			})
			.catch((e) => {
				console.error(e);
			}).finally(()=>{
				setLoading(false);
			})
	},[]);

	//переключание табов
	useEffect(()=>{
		console.log(getDayValue(0));
		setCurrentCorridorItem(getDayValue(0));
	},[weekLoaded]);


	//переключание табов
	useEffect(()=>{
		console.log('setCurrentCorridorItem by tab');
		setCurrentCorridorItem(getDayValue(value));
	},[value]);

	const onCalculateSave = function(params,corridor) {
		console.log('Params:',params);
		//setUserCorParams(params);

		console.log('Corridor:',corridor);
		setCurrentCorridorItem({...corridor,day:value,id:currentCorridorItem.id});
		//alert('Параметры расчета сохранены');

		setShowCalcButton(false);
		setWeekChanged(true);
	}

	const handleShowCalc = () => {
		setShowCalc(true);
	}

	const handleCloseCalc = () => {
		//setTimeout(()=> setShowCalc(false),300);
		setShowCalc(false);
	};

	const handleWeekSave = () => {
		setWeekChanged(false);

		let data = []
		for(var i=0; i<=7; i++) {
			data.push(getDayValue(i));
		}
		setLoading(true);
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'corridor';
		fetch(url,{
			method: "POST",
			body: JSON.stringify({corridors:data})
		})
			.then((res) => res.json())
			.then((json) => {
				alert('сохранили!')
			})
			.catch((e) => {
				setError("Ошибка сохранения");
				console.error(e);
			}).finally(()=> {
				setLoading(false);
			});
	};

	const handleWeekCancel = () => {
		setWeekChanged(false);
		alert('Почти сохранили!')
	};

	const handleCopyFrom = (day) => {
		//setCurrentCorridorItem(getDayValue(day));
		//setDayValue()
		handleUpdateItem({...getDayValue(day),day:value,id:currentCorridorItem.id});
		setWeekChanged(true);
	}

	const handleClearDay = () => {
		setCurrentCorridorItem({...initialFormState,day:value,id:currentCorridorItem.id});
	}

	return (
		<div className="app-page">
			<AppBar position="fixed" elevation={2}>
				<Toolbar>
					<IconButton component={RouterLink} to="/" edge="start">
						<ArrowBackIcon />
					</IconButton>
					<Typography variant="h6">
						Коридор питания
					</Typography>
					<IconButton onClick={()=>setWeekSingleMode(!weekSingleMode)}>
						<UpdateOutlinedIcon/>
					</IconButton>
					<IconButton edge="end" onClick={handleShowCalc}>
						<ExposureOutlinedIcon />
					</IconButton>
				</Toolbar>
				{!weekSingleMode ? (
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
					<Tab label="Ежедневно" {...a11yProps(0)} />
					<Tab label="Понедельник" {...a11yProps(1)} />
					<Tab label="Вторник" {...a11yProps(2)} />
					<Tab label="Среда" {...a11yProps(3)} />
					<Tab label="Четверг" {...a11yProps(4)} />
					<Tab label="Пятница" {...a11yProps(5)} />
					<Tab label="Суббота" {...a11yProps(6)} />
					<Tab label="Воскресенье" {...a11yProps(7)} />
					</Tabs>
				) : (
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
					<Tab label="Ежедневно" {...a11yProps(0)} />
					</Tabs>
				)}
			</AppBar>
			<div className="app-content">
				{showCalcButton && (
					<Button onClick={handleShowCalc} className="wide-start-action" variant="outlined" color="primary">Расчитать суточную потребность</Button>
				)}
				{weekLoaded && (
				<CorridorForm currentItem={currentCorridorItem}
							  onUpdate={handleUpdateItem}
							  onChange={handleChangeItem}/>
				)}
				{true && (
					<div className="fui-float-action-panel">
						<div className="fui-float-action-panel__inner">
							{weekChanged && (<Button className="fui-float-action-panel__secondary" variant="outlined" onClick={handleClearDay}><UndoOutlinedIcon /></Button>)}
							<Button className="fui-float-action-panel__secondary" variant="outlined" onClick={()=>handleCopyFrom(0)}><FileCopyOutlinedIcon /></Button>
							{weekChanged && (<Button className="fui-float-action-panel__primary" variant="contained" type="submit" onClick={handleWeekSave}>Применить</Button>)}
						</div>
					</div>
				)}
				<CorridorFormCalc show={showCalc} corParams={userCorParams} onSave={onCalculateSave}
								  afterClose={handleCloseCalc} day={value}/>
				{loading && <FuiLoader />}
			</div>
		</div>
	);
}
