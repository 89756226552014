import React, {useEffect, useState} from "react";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

function CorridorPanel(props) {
	const [diff, setDiff] = useState({});
	useEffect(()=>{
		let d = {
			k: 0,
			b: 0,
			z: 0,
			u: 0
		}
		if (props.total.k < props.day.k1) {
			d.k = props.day.k1 - props.total.k;
		} else if (props.total.k > props.day.k2) {
			d.k = props.day.k2 - props.total.k;
		}

		if (props.total.b < props.day.b1) {
			d.b = props.day.b1 - props.total.b;
		} else if (props.total.b > props.day.b2) {
			d.b = props.day.b2 - props.total.b;
		}

		if (props.total.z < props.day.z1) {
			d.z = props.day.z1 - props.total.z;
		} else if (props.total.z > props.day.z2) {
			d.z = props.day.z2 - props.total.z;
		}

		if (props.total.u < props.day.u1) {
			d.u = props.day.u1 - props.total.u;
		} else if (props.total.u > props.day.u2) {
			d.u = props.day.u2 - props.total.u;
		}

		d.k = +d.k.toFixed(0);
		d.b = +d.b.toFixed(1);
		d.z = +d.z.toFixed(1);
		d.u = +d.u.toFixed(1);

		setDiff(d);
		console.log(props.total);
	},[props]);
	let showCorridor = props.showCorridor;
	return (
		<div className="corridor-panel">
			<Link to="/corridor/" className="go-title">{props.title}<NavigateNextIcon /></Link>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<div className={"kbzu-item "+(diff.k ? (diff.k>0?"state-warning":"state-danger") : "state-good")}>
						<div className="kbzu-item__top">
							<div className="kbzu-item__name">Калории</div>
							<div className="kbzu-item__state">
								{diff.k ? (diff.k>0?"":<ReportProblemOutlinedIcon />) : <CheckCircleOutlineIcon />}
							</div>
						</div>
						<div className="kbzu-item__nums">
							<div className="kbzu-item__amount">
								<div className="kbzu-item__sub-title">Съедено</div>
								<div className="kbzu-item__amount-value">{props.total.k.toFixed(1)}</div>
							</div>
							{diff.k ? (
								<div className="kbzu-item__diff">
									{diff.k ? (diff.k>0? <div className="kbzu-item__sub-title">Недобор</div> : "") : <div className="kbzu-item__sub-title">Перебор</div>}
									<div className="kbzu-item__diff-value">{diff.k>0?'+'+diff.k:diff.k}</div>
								</div>
							) : null
							}
						</div>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={"kbzu-item "+(diff.b ? (diff.b>0?"state-warning":"state-danger") : "state-good")}>
						<div className="kbzu-item__top">
							<div className="kbzu-item__name">Белки</div>
							<div className="kbzu-item__state">
								{diff.b ? (diff.b>0?"":<ReportProblemOutlinedIcon />) : <CheckCircleOutlineIcon />}
							</div>
						</div>
						<div className="kbzu-item__nums">
							<div className="kbzu-item__amount">
								<div className="kbzu-item__sub-title">Съедено</div>
								<div className="kbzu-item__amount-value">{props.total.b.toFixed(1)}</div>
							</div>
							{diff.b ? (
								<div className="kbzu-item__diff">
									{diff.b ? (diff.b>0? <div className="kbzu-item__sub-title">Недобор</div> : "") : <div className="kbzu-item__sub-title">Перебор</div>}
									<div className="kbzu-item__diff-value">{diff.b>0?'+'+diff.b:diff.b}</div>
								</div>
								) : null
							}
						</div>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={"kbzu-item "+(diff.z ? (diff.z>0?"state-warning":"state-danger") : "state-good")}>
						<div className="kbzu-item__top">
							<div className="kbzu-item__name">Жиры</div>
							<div className="kbzu-item__state">
								{diff.z ? (diff.z>0?"":<ReportProblemOutlinedIcon />) : <CheckCircleOutlineIcon />}
							</div>
						</div>
						<div className="kbzu-item__nums">
							<div className="kbzu-item__amount">
								<div className="kbzu-item__sub-title">Съедено</div>
								<div className="kbzu-item__amount-value">{props.total.z.toFixed(1)}</div>
							</div>
							{diff.z ? (
								<div className="kbzu-item__diff">
									{diff.z ? (diff.z>0? <div className="kbzu-item__sub-title">Недобор</div> : "") : <div className="kbzu-item__sub-title">Перебор</div>}
									<div className="kbzu-item__diff-value">{diff.z>0?'+'+diff.z:diff.z}</div>
								</div>
							) : null
							}
						</div>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={"kbzu-item "+(diff.u ? (diff.u>0?"state-warning":"state-danger") : "state-good")}>
						<div className="kbzu-item__top">
							<div className="kbzu-item__name">Углеводы</div>
							<div className="kbzu-item__state">
								{diff.u ? (diff.u>0?"":<ReportProblemOutlinedIcon />) : <CheckCircleOutlineIcon />}
							</div>
						</div>
						<div className="kbzu-item__nums">
							<div className="kbzu-item__amount">
								<div className="kbzu-item__sub-title">Съедено</div>
								<div className="kbzu-item__amount-value">{props.total.u.toFixed(1)}</div>
							</div>
							{diff.u ? (
								<div className="kbzu-item__diff">
									{diff.b ? (diff.b>0? <div className="kbzu-item__sub-title">Недобор</div> : "") : <div className="kbzu-item__sub-title">Перебор</div>}
									<div className="kbzu-item__diff-value">{diff.u>0?'+'+diff.u:diff.u}</div>
								</div>
							) : null
							}
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}
export default CorridorPanel;