import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {FuiLoader} from "../common";
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Transition = React.forwardRef(function Transition(props, ref) {
	//return <Slide direction="up" ref={ref} {...props} />;
	//return <Zoom in="true" ref={ref} {...props} />;
	return <Fade in="true" ref={ref} {...props} />;
});

export default function (props) {
	const initialFormState = {
		vegetables: 0,
		fruits: 0,
		junkFood: 0,
		cigarettes: 0,
		pills: 0,
		vitamins: 0,
		other: 0,
	}
	const [counter, setCounter] = useState(initialFormState)
	const [error, setError] = useState('');
	const [inputNameError, setInputNameError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleInputChange = event => {
		const {name, value} = event.currentTarget;
		if (name==="name" && value) setInputNameError(false);
		setCounter({...counter, [name]: value})
	}

	const [open, setOpen] = useState(true);
	const handleSubmit = event => {
		event.preventDefault()
		if (!counter.name) {
			setInputNameError({error:"Пустое название продукта"});
			return;
		}

		//обновление на сервере
		setLoading(true);
		let api_root = process.env.REACT_APP_API_ROOT;
		if (0 && process.env.NODE_ENV === "development") {
			api_root = '/api/';
		}
		let url = api_root+'counters';
		if (counter.id) {
			url += counter.id;
		}
		fetch(url, {
			method: "POST",
			body: JSON.stringify(counter)
		})
			.then((res) => res.json())
			.then((json) => {
				// вызываем addcounter из хука из App
				props.addcounter(counter)
				// обнуляем форму, с помощью setCounter функции
				// которая у нас взята из хука в данном компоненте [1]
				setCounter(initialFormState)
				//закрыть диалог
				handleClose();
			})
			.catch((e) => {
				setError("Ошибка. Попробуйте позднее");
				console.error(e);
			})
			.finally(() => {
				setLoading(false)
			});
	}
	const handleClose = () => {
		setOpen(false);
		props.onClose();
	};

	const[vegetables, setVegetables] = useState(counter.vegetables);
	const handleStatusVegetables = value => {
		setVegetables(vegetables+value)
	}
	const[fruits, setFruits] = useState(counter.fruits);
	const handleStatusFruits = value => {
		setFruits(fruits+value)
	}
	const[junkFood, setJunkFood] = useState(counter.junkFood);
	const handleStatusJunkFood = value => {
		setJunkFood(junkFood+value)
	}
	const[cigarettes, setCigarettes] = useState(counter.cigarettes);
	const handleStatusCigarettes = value => {
		setCigarettes(cigarettes+value)
	}
	const[pills, setPills] = useState(counter.pills);
	const handleStatusPills = value => {
		setPills(pills+value)
	}
	const[vitamins, setVitamins] = useState(counter.vitamins);
	const handleStatusVitamins = value => {
		setVitamins(vitamins+value)
	}
	const[other, setOther] = useState(counter.other);
	const handleStatusOther = value => {
		setOther(other+value)
	}
	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar elevation={2}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6">
						Счетчики
					</Typography>
				</Toolbar>
			</AppBar>
			<div className="dialog-content">
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<div className="fui-data-form">
						<div className="b-spinner">
							<div className="b-spinner__label">Учет овощей</div>
							<div className="b-spinner__controls">
								<IconButton aria-label="delete" onClick={() => handleStatusVegetables(-1)}>
									<RemoveIcon />
								</IconButton>
								<div className="b-spinner__value">{vegetables}</div>
								<IconButton aria-label="delete" onClick={() => handleStatusVegetables(1)}>
									<AddIcon />
								</IconButton>
							</div>
						</div>
						<div className="b-spinner">
							<div className="b-spinner__label">Учет фруктов</div>
							<div className="b-spinner__controls">
								<IconButton aria-label="delete" onClick={() => handleStatusFruits(-1)}>
									<RemoveIcon />
								</IconButton>
								<div className="b-spinner__value">{fruits}</div>
								<IconButton aria-label="delete" onClick={() => handleStatusFruits(1)}>
									<AddIcon />
								</IconButton>
							</div>
						</div>
						<div className="b-spinner">
							<div className="b-spinner__label">Учет "вредной" еды</div>
							<div className="b-spinner__controls">
								<IconButton aria-label="delete" onClick={() => handleStatusJunkFood(-1)}>
									<RemoveIcon />
								</IconButton>
								<div className="b-spinner__value">{junkFood}</div>
								<IconButton aria-label="delete" onClick={() => handleStatusJunkFood(1)}>
									<AddIcon />
								</IconButton>
							</div>
						</div>
						<div className="b-spinner">
							<div className="b-spinner__label">Учет сигарет</div>
							<div className="b-spinner__controls">
								<IconButton aria-label="delete" onClick={() => handleStatusCigarettes(-1)}>
									<RemoveIcon />
								</IconButton>
								<div className="b-spinner__value">{cigarettes}</div>
								<IconButton aria-label="delete" onClick={() => handleStatusCigarettes(1)}>
									<AddIcon />
								</IconButton>
							</div>
						</div>
						<div className="b-spinner">
							<div className="b-spinner__label">Учет таблеток</div>
							<div className="b-spinner__controls">
								<IconButton aria-label="delete" onClick={() => handleStatusPills(-1)}>
									<RemoveIcon />
								</IconButton>
								<div className="b-spinner__value">{pills}</div>
								<IconButton aria-label="delete" onClick={() => handleStatusPills(1)}>
									<AddIcon />
								</IconButton>
							</div>
						</div>
						<div className="b-spinner">
							<div className="b-spinner__label">Учет витаминов</div>
							<div className="b-spinner__controls">
								<IconButton aria-label="delete" onClick={() => handleStatusVitamins(-1)}>
									<RemoveIcon />
								</IconButton>
								<div className="b-spinner__value">{vitamins}</div>
								<IconButton aria-label="delete" onClick={() => handleStatusVitamins(1)}>
									<AddIcon />
								</IconButton>
							</div>
						</div>
						<div className="fui-drawer-bottom">
							<Button variant="contained" type="submit">Сохранить</Button>
						</div>
						{error && <Alert severity="error">{error}</Alert>}
						{loading && <FuiLoader />}
					</div>
				</form>
			</div>
		</Dialog>
	);
}