import React, {useEffect, useRef, useState} from 'react'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Filters from "../common/Filters/Filters";
import {ProductList} from "../ProductsForm/products-list";
import {FuiLoader} from "../common";
import {AddProductForm} from "../ProductsForm/AddProductForm";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade in="true" ref={ref} {...props} />;
});

const AddMealContentProductDialog = props => {
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    //список продуктов
    let [error, setError] = useState();
    let [products, setProducts] = useState(null);
    let [productQuery, setProductQuery] = useState();
    let timerProductQuery = useRef(null);
    useEffect(() => {
        let isCurrent = true;
        //setProducts(null);
        let api_root = process.env.REACT_APP_API_ROOT;
        if (0 && process.env.NODE_ENV === "development") {
            api_root = '/api/';
        }
        let url = api_root+'products';
        if (productQuery) url=api_root+'products_find?q='+productQuery;
        const delay = productQuery ? 500 : 10;
        if (timerProductQuery.current) clearTimeout(timerProductQuery.current)
        console.log("set new request = "+productQuery);
        timerProductQuery.current = setTimeout(() => fetch(url)
            .then((res) => res.json())
            .then((json) => {
                if (isCurrent) {
                    setProducts(json.products)
                    if (timerProductQuery.current) clearTimeout(timerProductQuery.current)
                }

            })
            .catch((e) => {
                if (isCurrent) {
                    setError("Ошибка загрузки. Попробуйте позднее");
                    console.error(e);
                    if (timerProductQuery.current) clearTimeout(timerProductQuery.current)
                }
            }), delay);

        return () => {
            isCurrent = false
        };
    }, [productQuery])
    //*****

    // клик по продукту из списка
    const selectProduct = product => {
        props.onSubmit(product);
        handleClose();
    }
    //*****

    //фильтр
    const ProductsFiltersItems = [
        "Все",
        "Недавние"
    ]
    //*****

    //добавление продукта
    const addProduct = product => {
        //TODO save to server
        //TODO prepend to list
        product.id = products.length + 1
        setProducts([...products, product])
    }
    const [adding, setAdding] = useState(false)
    const handleCloseAdding = () => {
        setTimeout(()=> setAdding(false),300);
    };
    const initialFormState = {
        id: null,
        name: '',
        k: '', b: '', z: '', u: '',
        comment: ''
    }
    const [currentProduct, setCurrentProduct] = useState(initialFormState)
    //*****


    //search-panel убрать в компонент <SearchProduct onChange={(e) => setProductQuery(e.target.value)} />
    return (
        <Dialog fullScreen open={open} onClose={handleClose}
                TransitionComponent={Transition} closeAfterTransition={true}>
            <AppBar elevation={2}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Поиск продукта
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="dialog-content">
                <div className="search-panel">
                    <SearchIcon />
                    <TextField id="product-search" label="Поиск продукта" variant="outlined"
                               onChange={(e) => setProductQuery(e.target.value)} />
                </div>
                <Filters items={ProductsFiltersItems} />

                {adding && (
                    <AddProductForm adding={adding} addProduct={addProduct} onClose={handleCloseAdding}/>
                )}

                {products ? (
                    <ProductList items={products} editRow={selectProduct} />
                ) : !error ? (
                    <FuiLoader />
                ) : null}

                <Fab className="fab" aria-label="Добавить" onClick={() => setAdding(true)}>
                    <AddIcon />
                </Fab>
            </div>
        </Dialog>
    )
}

export { AddMealContentProductDialog }
