import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Link as RouterLink} from "react-router-dom";
import { useHistory } from "react-router-dom";

function TopBarContext(props) {
	const history = useHistory();
	const handleClose = () => {
		props.onClose()
	};
	return (
		<header className="top-bar">
			<div className="top-bar__left">
				{!props.onClose ? (
					props.href ? (
						<IconButton component={RouterLink} to={props.href}>
							<ArrowBackIcon />
						</IconButton>
					) : (
						<IconButton onClick={() => {history.goBack();}}>
							<ArrowBackIcon />
						</IconButton>
					)
				) : (
					<IconButton onClick={handleClose}>
						<ArrowBackIcon />
					</IconButton>
				)
				}
			</div>
			<div className="top-bar__center">
				<div className="top-bar__title">{props.title}</div>
			</div>
			<div className="top-bar__right">
				{props.children}
			</div>
		</header>
	);
}

export default TopBarContext;