import React, {useEffect} from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

export default function (props) {
	const [state, setState] = React.useState(false);

	useEffect(()=>{
		setState(true);
	},[]);

	const handleOpen = () => setState(true);
	const handleClose = () => {
		setState(false);
		props.onClose();
	}

	return (
		<SwipeableDrawer
			anchor="bottom"
			open={state}
			onClose={handleClose}
			onOpen={handleOpen}
		>
			<div className="fui-drawer-back-drag"></div>
			<div className="fui-drawer-title">Ваш вес</div>
			<form onSubmit={handleClose}>
				<TextField name="weight" value={props.value}
				           onChange=""
				           inputProps={{ inputMode: 'numeric' }}
				           label="Вес тела, кг" variant="outlined" />
				<div className="fui-drawer-bottom fui-drawer-bottom--2actions">
					<Button variant="outlined" onClick={handleClose}>Отмена</Button>
					<Button variant="contained" type="submit">Сохранить</Button>
				</div>
			</form>
		</SwipeableDrawer>
	);
}