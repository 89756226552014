import React, {useEffect, useRef, useState} from "react";
import TopBarContext from "../../components/common/TopBarContext/TopBarContext";
import WeekCalendar from "../../components/meal/WeekCalendar";
import KbzuTotal from "../../components/meal/KbzuTotal";
import {AddMealPart} from "../../components/meal/AddMealPart";
import {MealPartList} from "../../components/meal/MealPartList";
import {FuiLoader} from "../../components/common";
import CalendarDialog from "../../components/common/CalendarDialog/CalendarDialog";
import FuiFab from "../../components/common/FuiFab/FuiFab";

export default function MealPage(props) {
	let [error, setError] = useState();
	let [parts, setParts] = useState(null);
	let [totals, setTotals] = useState({
		k: 0, b: 0, z: 0, u: 0,
	});
	const [loading, setLoading] = useState(false);
	//TODO get from day info (server)
	let [dayinfo, setDayinfo] = useState({
		k1: 0, k2: 0,
		b1: 0, b2: 0,
		z1: 0, z2: 0,
		u1: 0, u2: 0,
		date: '',
		sid: 0
	});
	let [partQuery, setPartQuery] = useState();

	/*
		const partsData = [
			{ id: 1, name: 'Tania', k: 'floppydiskette' },
			{ id: 2, name: 'Max', k: 'maxfarseer' },
		]*/
	//const [parts, setParts] = useState(partsData)

	window.console.log('props.match.params.date=',props.match.params.date);
	let [curDate, setCurDate] = useState(new Date().yyyymmdd());
	const [adding, setAdding] = useState(false);
	// начальное значение для формы редактирования
	// так как мы не знаем, кто редактируется - пустые поля

	const addPart = (updatedPart,isNew) => {
		let id = updatedPart.id;
		if (isNew) {
			setParts([...parts, updatedPart])
		} else {
			setParts(parts.map(part => (part.id === id ? updatedPart : part)))
		}
	}

	useEffect(() => {
		if (props.match.params.date && props.match.params.date.length===10) {
			//TODO check date format
			setCurDate(new Date(props.match.params.date).yyyymmdd());
		}else{
			setCurDate(new Date().yyyymmdd());
		}
	}, [props]);

	let timerPartQuery = useRef(null);
	useEffect(() => {
		let isCurrent = true;
		setParts(null);
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealpart';
		if (curDate) {
			url += '/'+curDate;
		}
		const delay = 50;
		if (timerPartQuery.current) clearTimeout(timerPartQuery.current)
		console.log("set new request = "+partQuery);
		timerPartQuery.current = setTimeout(() => fetch(url)
			.then((res) => res.json())
			.then((json) => {
				if (isCurrent) {
					setParts(json.mealparts)
					if (timerPartQuery.current) clearTimeout(timerPartQuery.current)
				}
			})
			.catch((e) => {
				setError("Ошибка загрузки. Попробуйте позднее");
				console.error(e);
				if (timerPartQuery.current) clearTimeout(timerPartQuery.current)
			}), delay);
		//****

		let url_mealday = api_root+'mealday'+'/'+curDate;
		fetch(url_mealday)
			.then((res) => res.json())
			.then((json) => {
				setDayinfo(json.mealday)
			})
			.catch((e) => {
				setError("Ошибка загрузки. Попробуйте позднее");
				console.error(e);
			});
	}, [curDate])

	useEffect(()=>{
		let t = {
			b: 0, z: 0, u: 0, k: 0
		}
		if (parts && parts.length) {
			parts.map(part => {
				t.k += Number(part.k);
				t.b += Number(part.b);
				t.z += Number(part.z);
				t.u += Number(part.u);
			});
		}
		setTotals(t);
	}, [parts]);

	//TODO разбивка на страницы или бесконечный скролл с догрузкой данных по-частям
/*
	const PartTypesList = {
		1: "Завтрак",
		2: "Ланч",
		3: "Обед",
		4: "Полдник",
		5: "Ужин",
		6: "Перекус",
		7: "Свое",
	}*/

	const PartTypesList = [
		"",
		"Завтрак1",
		"Ланч",
		"Обед",
		"Полдник",
		"Ужин",
		"Перекус",
		"Свое",
	]

	const handleCloseAdding = () => {
		console.log('handleCloseAdding');
		setAdding(false);
	};

	const handleOpenAdd = () => {
		console.log('add part item');
		setAdding(true);
	}
	return (
		<div className="app-page">
			<TopBarContext href="/" title={"Питание - "+curDate}>
				<CalendarDialog date={curDate} onSelect={setCurDate}/>
			</TopBarContext>
			<div className="single-wrap">
				<WeekCalendar date={curDate} onSelect={setCurDate} startFromMonday={false}/>
				<KbzuTotal day={dayinfo} total={totals} showCorridor={false}/>
			</div>
			<div className="app-content app-content--sheet pdt-0">
				{adding && (
					<AddMealPart date={curDate} tpname={PartTypesList} adding={adding} onSelect={addPart} onClose={handleCloseAdding}/>
				)}

				{parts ? (
					<MealPartList items={parts} tpname={PartTypesList} />
				) : !error ? (
					<FuiLoader />
				) : null}
				<FuiFab setClick={handleOpenAdd} />

			</div>
		</div>
	)
}