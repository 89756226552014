import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';

function TopBar(props) {
	return (
		<header className="top-bar">
			<div className="top-bar__left">
				<IconButton>
					<MenuIcon />
				</IconButton>
			</div>
			<div className="top-bar__center">
				<div className="top-bar__title">
					{props.title}
					{props.logo &&
						<img src={props.logo} alt=""/>
					}
				</div>
			</div>
			<div className="top-bar__right">
				{props.children}
			</div>
		</header>
	);
}

export default TopBar;