import React, {useEffect, useState} from 'react'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const CorridorForm = props => {
	const [item, setItem] = useState(props.currentItem);
	const [error, setError] = useState('');
	const [isChanged, setIsChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	// используем effect-hook
	useEffect(
		() => {
			// вызывай данную функцию
			setItem(props.currentItem)
		},
		[props.currentItem] // всегда, если изменились props
	)

	useEffect(
		() => {
			return () =>
			{
				console.log('unmount form update');
				//props.onUpdate(item);
			}
		},
		[]
	)


	useEffect(
		() => {
			console.log('form update');
			props.onUpdate(item);
		},
		[item]
	)

	const handleInputChange = event => {
		const {name, value} = event.target
		setItem({...item, [name]: value})
		//setIsChanged(true);
		props.onChange(item.day);
	}
	const handleSubmit = event => {
		event.preventDefault()
		props.onUpdate(item);
	};

	return (
		<form noValidate autoComplete="off" onSubmit={handleSubmit}>
			<Paper className="fui-paper">
				<div className="fui-paper__title">Калорийность, ккал.</div>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField value={item.k1} name="k1" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не менее" variant="outlined" />
					</Grid>
					<Grid item xs={6}>
						<TextField value={item.k2} name="k2" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не более" variant="outlined" />
					</Grid>
				</Grid>
			</Paper>
			<Paper className="fui-paper">
				<div className="fui-paper__title">Белки, гр.</div>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField value={item.b1} name="b1" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не менее" variant="outlined" />
					</Grid>
					<Grid item xs={6}>
						<TextField value={item.b2} name="b2" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не более" variant="outlined" />
					</Grid>
				</Grid>
			</Paper>
			<Paper className="fui-paper">
				<div className="fui-paper__title">Жиры, гр.</div>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField value={item.z1} name="z1" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не менее" variant="outlined" />
					</Grid>
					<Grid item xs={6}>
						<TextField value={item.z2} name="z2" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не более" variant="outlined" />
					</Grid>
				</Grid>
			</Paper>
			<Paper className="fui-paper">
				<div className="fui-paper__title">Углеводы, гр.</div>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField value={item.u1} name="u1" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не менее" variant="outlined" />
					</Grid>
					<Grid item xs={6}>
						<TextField value={item.u2} name="u2" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Не более" variant="outlined" />
					</Grid>
				</Grid>
			</Paper>
			<Paper className="fui-paper">
				<div className="fui-paper__title">Вода, мл.</div>
				<TextField className="w-100" value={item.w1} name="w1" onChange={handleInputChange} inputProps={{ inputMode: 'numeric' }} label="Цель" variant="outlined" />
			</Paper>
			{isChanged && (
				<div className="fui-float-action-panel">
					<div className="fui-float-action-panel__inner">
						<Button className="fui-float-action-panel__secondary" variant="outlined"><UndoOutlinedIcon /></Button>
						<Button className="fui-float-action-panel__secondary" variant="outlined"><FileCopyOutlinedIcon /></Button>
						<Button className="fui-float-action-panel__primary" variant="contained" type="submit">Применить</Button>
					</div>
				</div>
			)}
		</form>
	)
}

export { CorridorForm }