import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {makeServer} from './server';

import Firebase, { FirebaseContext } from './components/fire/';

if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_FAKEAPI==="true") {
        makeServer()
    }
}

ReactDOM.render(
    <React.StrictMode>
        <FirebaseContext.Provider value={new Firebase()}>
            <App />
        </FirebaseContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

const sw_config = {
    onSuccess: function(reg) {
        alert('Добро пожаловать!');
    },

    onUpdate: function(reg) {
        window.location.reload();
        //alert('Оффлайн версия обновлена');
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(sw_config);