import React from 'react'
import Alert from "@material-ui/lab/Alert";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';

export const MealPartList = props => {
	/*
	const handleDeleteItem = id => {
		let answer = window.confirm('Are you sure?')

		if (answer) {
			props.deleteItem(id)
		}
	}
	//<button onClick={() => handleDeleteItem(item.id)}>Delete</button>
	*/
	return props.items && props.items.length > 0 ? (
		<List className="fui-list-bordered" component="div">
			{props.items.map(item => (
				<ListItem component={Link} to={"/meal/"+item.date+"/"+item.id+"/"} key={item.id} button>
					<ListItemText
						primary={
							<React.Fragment>
								<span className="fui-list-name">
									<span className="fui-list-name__value">
										{props.tpname[item.mid]}
									</span>
									<span className="time-view"><AccessTimeIcon />{item.time}</span>
								</span>
							</React.Fragment>
						}
						secondary={
							<React.Fragment>
							<span className="food-data">
								<span className="food-data__left">
									<span className="food-data__item">Б: {item.b}</span>
									<span className="food-data__item">Ж: {item.z}</span>
									<span className="food-data__item">У: {item.u}</span>
								</span>
								<span className="food-data__right">
									<span className="food-data__item">{item.k} ккал</span>
								</span>
							</span>
							</React.Fragment>
						}
					/>
				</ListItem>
			))}
		</List>
	) : (
		<Alert severity="info">Нет данных</Alert>
	)
}