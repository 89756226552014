import React, {useEffect, useRef, useState} from "react";
import TopBar from "../../components/common/TopBar/TopBar";
import Filters from "../../components/common/Filters/Filters";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import {ProductList} from '../../components/ProductsForm/products-list';
import {EditProductForm} from '../../components/ProductsForm/EditProductForm';
import {AddProductForm} from '../../components/ProductsForm/AddProductForm';
import {FuiLoader} from "../../components/common";
import SearchIcon from '@material-ui/icons/Search';
import FuiFab from "../../components/common/FuiFab/FuiFab";

export default function ProductsPage() {
	let [error, setError] = useState();
	let [products, setProducts] = useState(null);
	let [productQuery, setProductQuery] = useState();

/*
	const productsData = [
		{ id: 1, name: 'Tania', k: 'floppydiskette' },
		{ id: 2, name: 'Max', k: 'maxfarseer' },
	]*/
	//const [products, setProducts] = useState(productsData)

	// флаг editing - изначально false, функция установки флага
	//const [open, setOpen] = useState(true);
	const [adding, setAdding] = useState(false)
	const [editing, setEditing] = useState(false)
	// начальное значение для формы редактирования
	// так как мы не знаем, кто редактируется - пустые поля
	const initialFormState = {
		id: null,
		name: '',
		k: '', b: '', z: '', u: '',
		comment: ''
	}
	// значение "текущий пользователь на редактировании" + функция установки этого значения
	const [currentProduct, setCurrentProduct] = useState(initialFormState)

	const addProduct = product => {
		//TODO save to server
		//TODO prepend to list
		product.id = products.length + 1
		setProducts([...products, product])
	}

	const deleteProduct = id => {
		//TODO save to server
		//TODO remove from list OR reload
		//setEditing(false)
		setProducts(products.filter(product => product.id !== id))
	}

	// обновление пользователя
	const updateProduct = (id, updatedProduct) => {
		//TODO save to server

		// когда мы готовы обновить пользователя, ставим флажок editing в false
		//setEditing(false)
		// и обновляем пользователя, если нашли его по id

		//update in list
		setProducts(products.map(product => (product.id === id ? updatedProduct : product)))
	}

	// редактирование пользователя
	const editRow = product => {
		//TODO get from server

		// готовы редактировать - флажок в true
		setEditing(true)
		// устанавливаем значения полей для формы редактирования
		// на основании выбранного "юзера"
		setCurrentProduct({
			id: product.id,
			name: product.name,
			k: product.k,
			b: product.b,
			z: product.z,
			u: product.u,
			comment: product.comment,
		})
	}


	let timerProductQuery = useRef(null);
	useEffect(() => {
		let isCurrent = true;
		//setProducts(null);
		let api_root = process.env.REACT_APP_API_ROOT;
		if (0 && process.env.NODE_ENV === "development") {
			api_root = '/api/';
		}
		let url = api_root+'products';
		if (productQuery) url=api_root+'products_find?q='+productQuery;
		const delay = 500;
		if (timerProductQuery.current) clearTimeout(timerProductQuery.current)
		console.log("set new request = "+productQuery);
		timerProductQuery.current = setTimeout(() => fetch(url)
			.then((res) => res.json())
			.then((json) => {
				if (isCurrent) {
					setProducts(json.products)
					if (timerProductQuery.current) clearTimeout(timerProductQuery.current)
				}

			})
			.catch((e) => {
				if (isCurrent) {
					setError("Ошибка загрузки. Попробуйте позднее");
					console.error(e);
					if (timerProductQuery.current) clearTimeout(timerProductQuery.current)
				}
			}), delay);

		return () => {
			isCurrent = false
		};
	}, [productQuery])

	//TODO разбивка на страницы или бесконечный скролл с догрузкой данных по-частям

	const handleClose = () => {
		setTimeout(()=> setEditing(false),300);
	};

	const handleCloseAdding = () => {
		setTimeout(()=> setAdding(false),300);
	};

	const ProductsFiltersItems = [
		"Все",
		"Недавние",
	]

	const handleClick = () => setAdding(true)

	return (
		<div className="app-page">
			<TopBar title="Продукты" />
			<div className="app-content app-content--sheet">
				<div className="search-panel">
					<SearchIcon />
					<TextField id="product-search" label="Поиск продукта" variant="outlined"
							   onChange={(e) => setProductQuery(e.target.value)} />
				</div>
				<Filters items={ProductsFiltersItems} />
				{editing && (
					<EditProductForm
						editing={editing}
						setEditing={setEditing}
						currentProduct={currentProduct}
						updateProduct={updateProduct}
						onClose={handleClose}
						onDelete={deleteProduct}
					/>
				)}
				{adding && (
					<AddProductForm adding={adding} addProduct={addProduct} onClose={handleCloseAdding}/>
				)}

				{products ? (
					<ProductList items={products} editRow={editRow} deleteProduct={deleteProduct} />
				) : !error ? (
					<FuiLoader />
				) : null}
				<FuiFab setClick={handleClick} />
			</div>
		</div>
	);
}

