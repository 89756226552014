import React, {useState} from "react";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

function CalendarDialog(props) {
	const [selectedDate, setSelectedDate] = useState(new Date(props.date));
	const handleDateChange = (date) => {
		setSelectedDate(date);
		props.onSelect(date.yyyymmdd());
	};
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
			<KeyboardDatePicker
				margin="normal"
				id="date-picker-dialog"
				label="Выберите день"
				format="yyyyy-MM-dd"
				value={selectedDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}
export default CalendarDialog;