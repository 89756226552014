import React, {useState} from "react";
import Chip from '@material-ui/core/Chip';

function Filters(props) {

	const [isActive, setActive] = useState("false");

	const handleToggle = () => {
		setActive(!isActive);
	};

	return (
		<div className="filters">
			{props.items.map((item,index) => (
				item && (
					<Chip className={isActive ? "state-active" : null} label={item} onClick={handleToggle} variant="outlined" />
				)
			))}
		</div>
	)
}
export default Filters;