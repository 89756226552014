import React, {useEffect, useState} from "react";
import Alert from "@material-ui/lab/Alert";
import Card from '@material-ui/core/Card';
import {Link, Link as RouterLink} from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

function TodayMealList(props) {
	const [title, setTitle] = useState(props.date);
	useEffect(()=>{
		if (props.date === new Date().yyyymmdd()) {
			setTitle('сегодня');
		}else{
			setTitle(props.date);
		}
	},[props.date]);
	return props.items && props.items.length > 0 ? (
		<div>
			<Link to={"/meal/"+props.date} className="go-title">Питание {title}<NavigateNextIcon /></Link>
			<Card className="fui-today-meal">
				{props.items.map(item=>
					(
						<CardActionArea component={RouterLink} to={"/meal/"+item.part.date+"/"+item.part.id+"/"} key={item.id} className="fui-today-meal__item">
							<div className="fui-today-meal__item-top">
								<div className="fui-today-meal__item-part">
									<div className="fui-today-meal__item-name">{item.part.name}</div>
									<div className="fui-today-meal__item-time"><AccessTimeIcon />{item.part.time}</div>
								</div>
								<div className="fui-today-meal__item-total">
									<div className="fui-today-meal__item-total-amount">{item.part.amount} гр.</div>
									<div className="fui-today-meal__item-total-k"><span>{item.part.k}</span> ккал</div>
								</div>
							</div>
							<div className="fui-today-meal__item-products">
								{item.meals && (item.meals.length > 0) && item.meals.map(meal=>
									(
										<div className="fui-today-meal__item-product" key={meal.id}>
											<div className="fui-today-meal__item-product-name">
												<div className="fui-today-meal__item-product-marker">
													{meal.status>0 ? (<CheckIcon />) : (<div className="fui-today-meal__item-product-marker-empty"></div>)}
												</div>
												<div className="fui-today-meal__item-product-name-value">{meal.name}</div>
											</div>
											<div className="fui-today-meal__item-product-total">
												<div className="fui-today-meal__item-product-total-amount">{meal.amount} гр.</div>
												<div className="fui-today-meal__item-product-total-k"><span>{meal.k}</span> ккал</div>
											</div>
										</div>
									)
								)}
							</div>
						</CardActionArea>
					)
				)}
			</Card>
		</div>
	) : (
		<div>
			<Link to={"/meal/"+props.date} className="go-title">Питание {title}<NavigateNextIcon /></Link>
			<Alert severity="info">Нет данных</Alert>
		</div>
	)
}

export default TodayMealList;