import React from "react";
import Card from "@material-ui/core/Card";
import Skeleton from '@material-ui/lab/Skeleton';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

function TodayMealListSkeleton(props) {
	return (
		<div>
			<div className="go-title">Питание <NavigateNextIcon /></div>
			<Card className="fui-today-meal">
				<div className="fui-today-meal__item">
					<div className="fui-today-meal__item-top">
						<div className="fui-today-meal__item-part">
							<div className="fui-today-meal__item-name"><Skeleton variant="text" width={50} /></div>
							<div className="fui-today-meal__item-time"><Skeleton variant="text" width={47} /></div>
						</div>
						<div className="fui-today-meal__item-total">
							<div className="fui-today-meal__item-total-amount"><Skeleton variant="text" width={34} /></div>
							<div className="fui-today-meal__item-total-k"><Skeleton variant="text" width={75} /></div>
						</div>
					</div>
					<div className="fui-today-meal__item-products">
						<div className="fui-today-meal__item-product">
							<div className="fui-today-meal__item-product-name">
								<div className="fui-today-meal__item-product-name-value"><Skeleton variant="text" /></div>
							</div>
							<div className="fui-today-meal__item-product-total">
								<div className="fui-today-meal__item-product-total-amount"><Skeleton variant="text" width={34} /></div>
								<div className="fui-today-meal__item-product-total-k"><Skeleton variant="text" width={75} /></div>
							</div>
						</div>
						<div className="fui-today-meal__item-product">
							<div className="fui-today-meal__item-product-name">
								<div className="fui-today-meal__item-product-name-value"><Skeleton variant="text" width={86} /></div>
							</div>
							<div className="fui-today-meal__item-product-total">
								<div className="fui-today-meal__item-product-total-amount"><Skeleton variant="text" width={34} /></div>
								<div className="fui-today-meal__item-product-total-k"><Skeleton variant="text" width={75} /></div>
							</div>
						</div>
						<div className="fui-today-meal__item-product">
							<div className="fui-today-meal__item-product-name">
								<div className="fui-today-meal__item-product-name-value"><Skeleton variant="text" width={110} /></div>
							</div>
							<div className="fui-today-meal__item-product-total">
								<div className="fui-today-meal__item-product-total-amount"><Skeleton variant="text" width={34} /></div>
								<div className="fui-today-meal__item-product-total-k"><Skeleton variant="text" width={75} /></div>
							</div>
						</div>
					</div>
				</div>
				<div className="fui-today-meal__item">
					<div className="fui-today-meal__item-top">
						<div className="fui-today-meal__item-part">
							<div className="fui-today-meal__item-name"><Skeleton variant="text" width={50} /></div>
							<div className="fui-today-meal__item-time"><Skeleton variant="text" width={47} /></div>
						</div>
						<div className="fui-today-meal__item-total">
							<div className="fui-today-meal__item-total-amount"><Skeleton variant="text" width={34} /></div>
							<div className="fui-today-meal__item-total-k"><Skeleton variant="text" width={75} /></div>
						</div>
					</div>
					<div className="fui-today-meal__item-products">
						<div className="fui-today-meal__item-product">
							<div className="fui-today-meal__item-product-name">
								<div className="fui-today-meal__item-product-name-value"><Skeleton variant="text" /></div>
							</div>
							<div className="fui-today-meal__item-product-total">
								<div className="fui-today-meal__item-product-total-amount"><Skeleton variant="text" width={34} /></div>
								<div className="fui-today-meal__item-product-total-k"><Skeleton variant="text" width={75} /></div>
							</div>
						</div>
						<div className="fui-today-meal__item-product">
							<div className="fui-today-meal__item-product-name">
								<div className="fui-today-meal__item-product-name-value"><Skeleton variant="text" width={86} /></div>
							</div>
							<div className="fui-today-meal__item-product-total">
								<div className="fui-today-meal__item-product-total-amount"><Skeleton variant="text" width={34} /></div>
								<div className="fui-today-meal__item-product-total-k"><Skeleton variant="text" width={75} /></div>
							</div>
						</div>
						<div className="fui-today-meal__item-product">
							<div className="fui-today-meal__item-product-name">
								<div className="fui-today-meal__item-product-name-value"><Skeleton variant="text" width={110} /></div>
							</div>
							<div className="fui-today-meal__item-product-total">
								<div className="fui-today-meal__item-product-total-amount"><Skeleton variant="text" width={34} /></div>
								<div className="fui-today-meal__item-product-total-k"><Skeleton variant="text" width={75} /></div>
							</div>
						</div>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default TodayMealListSkeleton;