import React, {useEffect, useRef, useState} from "react";
import KbzuTotal from "../../components/meal/KbzuTotal";
import TopBarContext from "../../components/common/TopBarContext/TopBarContext";
import {MealContentList} from "../../components/meal/MealContentList";
import {AddMealContent} from "../../components/meal/AddMealContent";
import {FuiLoader} from "../../components/common";
import Menu from '@material-ui/core/Menu';
import MenuItem from "@material-ui/core/MenuItem";
import {IconButton} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import {useHistory} from "react-router-dom";
import { toast } from 'react-toastify';
import FuiFab from "../../components/common/FuiFab/FuiFab";

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export default function MealPartPage(props) {
	let [error, setError] = useState();
	let [parts, setParts] = useState(null);
	let [mealContentList, setMealContentList] = useState(null);
	const [loading, setLoading] = useState(false);

	//TODO get from other parts
	let [totalsOther, setTotalsOther] = useState(null);
	let [totalsPart, setTotalsPart] = useState({
		k: 0, b: 0, z: 0, u: 0
	});
	const totalsPartPrev = usePrevious(totalsPart);
	//totals = totalsOther+totalsPart
	let [totals, setTotals] = useState({
		k: 0, b: 0, z: 0, u: 0,
	});
	//TODO get from day info (server)
	let [dayinfo, setDayinfo] = useState({
		k1: 0, k2: 0,
		b1: 0, b2: 0,
		z1: 0, z2: 0,
		u1: 0, u2: 0,
		date: '',
		sid: 0
	});

	//бинд кнопки возврата и заголовка
	let [backUrl, setBackUrl] = useState("/meal/");
	const [currentFid,setCurrentFid] = useState();
	const [currentFidInfo,setCurrentFidInfo] = useState();
	//const [currentDate,setCurrentDate] = useState();
	useEffect(()=>{
		if (props.match.params.eating) {
			//FID - ИД факт приема пищи
			setCurrentFid(Number(props.match.params.eating));
		}
		if (props.match.params.date && props.match.params.eating) {
			//setCurrentDate(props.match.params.date||);
			setBackUrl("/meal/"+props.match.params.date+"/");
		}
	},[props]);
	//*****

	//TODO переместить куда-то выше всех страниц, загружать один раз при первом входе в приложение / или кешировать через localStorage
	const PartTypesList = [
		"",
		"Завтрак1",
		"Ланч",
		"Обед",
		"Полдник",
		"Ужин",
		"Перекус",
		"Свое",
	];

	//пересчет суммарных значений
	useEffect(()=>{
		if (!currentFid) return;
		if (parts === null) return;
		//totalsOther - суммы всех частей кроме текущей
		//totals = totalsOther + SUM(текущие продукты)
		let t = {
			b: 0, z: 0, u: 0, k: 0
		}
		let cur = {
			b: 0, z: 0, u: 0, k: 0
		}
		if (parts && parts.length) {
			parts.map(part => {
				if (Number(part.id) === currentFid) {
					cur.k = Number(part.k);
					cur.b = Number(part.b);
					cur.z = Number(part.z);
					cur.u = Number(part.u);
					setCurrentFidInfo(part);
					console.log(part);
				}else{
					t.k += Number(part.k);
					t.b += Number(part.b);
					t.z += Number(part.z);
					t.u += Number(part.u);
				}
			});
		}
		setTotalsOther(t);
		setTotalsPart(cur);
	}, [parts,currentFid]);

	useEffect(()=>{
		if (mealContentList === null) return;
		if (parts === null) return;
		if (totalsOther === null) return;
		let t = {
			b: 0, z: 0, u: 0, k: 0
		}
		if (mealContentList && mealContentList.length) {
			mealContentList.map(meal => {
					t.k += meal.k*meal.amount*0.01;
					t.b += meal.b*meal.amount*0.01;
					t.z += meal.z*meal.amount*0.01;
					t.u += meal.u*meal.amount*0.01;

			});
		}
		t.k = t.k.toFixed(0);
		t.b = t.b.toFixed(1);
		t.z = t.z.toFixed(1);
		t.u = t.u.toFixed(1);
		//***
		setTotalsPart(t);
		t.k = totalsOther.k + Number(t.k);
		t.b = totalsOther.b + Number(t.b);
		t.z = totalsOther.z + Number(t.z);
		t.u = totalsOther.u + Number(t.u);
		t.k = Number(t.k.toFixed(0));
		t.b = Number(t.b.toFixed(1));
		t.z = Number(t.z.toFixed(1));
		t.u = Number(t.u.toFixed(1));
		setTotals(t);
	}, [parts,mealContentList,totalsOther]);

	useEffect(()=>{
		if (!totalsPartPrev) return;
		if (totalsPartPrev.k != totalsPart.k
			|| totalsPartPrev.b != totalsPart.b
			|| totalsPartPrev.z != totalsPart.z
			|| totalsPartPrev.u != totalsPart.u ) {
			console.log('save part totals',totalsPartPrev,totalsPart);

			//TODO save to server?
		}else{
			//console.log('save part totals - skip');
		}
	}, [totalsPart]);

	const [editing, setEditing] = useState(false)
	// начальное значение для формы редактирования
	// так как мы не знаем, кто редактируется - пустые поля
	const defaultMealContent = {
		id: null,
		fid: props.match.params.eating,
		pid: 0,
		name: '',
		k: '', b: '', z: '', u: '',
		amount: '',
		comment: '',
		status: 0
	}
	// значение "текущая строчка на редактировании" + функция установки этого значения
	const [currentMealContent, setCurrentMealContent] = useState(defaultMealContent)

	// удаление строчки
	const deleteRow = id => {
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealcontent';
		url=url+'/'+id;
		fetch(url,{
			method: "POST",
			body: JSON.stringify({
				delete: true
			})
		})
			.then((res) => res.json())
			.then((json) => {
				toast.dark("удалено");
			})
			.catch((e) => {
				setError("Ошибка. Попробуйте позднее");
				console.error(e);
			});

		//TODO remove from list OR reload
		setMealContentList(mealContentList.filter(meal => meal.id !== id))
	}

	// отмечаем галочкой
	const markRow = (id,newStatus) => {
		//TODO save to server
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealcontent';
		url=url+'/'+id;
		fetch(url,{
			method: "POST",
			body: JSON.stringify({
				status: newStatus ? 1 : 0
			})
		})
			.then((res) => res.json())
			.then((json) => {
				toast.dark("Помечен как съеденный");
			})
			.catch((e) => {
				setError("Ошибка. Попробуйте позднее");
				console.error(e);
			});
		//TODO remove from list OR reload
		setMealContentList(mealContentList.map(meal => {
			if (meal.id === id) {
				meal.status = newStatus;
				return meal;
			}else{
				return meal;
			}
		}))
	}

	// обновление строчки
	const saveRow = (updatedMeal) => {
		//TODO save to server
		let isNew = (!updatedMeal.id);
		let id = updatedMeal.id;
		//TODO save to server
		setLoading(true);
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealcontent';
		url=url+'/'+(id?id:'0');
		fetch(url,{
			method: "POST",
			body: JSON.stringify(updatedMeal)
		})
			.then((res) => res.json())
			.then((json) => {
				let res = json.mealcontent;
				if (isNew) {
					//updatedMeal.id = mealContentList.length + 1
					setMealContentList([...mealContentList, res])
				} else {
					if (updatedMeal.fid!=currentFid) {
						//move from current
						setMealContentList(mealContentList.filter(meal => meal.id !== id))
						toast.success('перемещено');
					}else{
						//update in current
						setMealContentList(mealContentList.map(meal => (meal.id === id ? res : meal)))
					}

				}
			})
			.catch((e) => {
				setError("Ошибка сохранения");
				console.error(e);
			}).finally(()=> {
				setLoading(false);
			});
	}

	//TODO разбивка на страницы или бесконечный скролл с догрузкой данных по-частям

	const handleCloseEditing = () => {
		console.log('close meal edit')
		setEditing(false)
		//setTimeout(()=> setEditing(false),300);
	};

	//*****
	//TODO убрать меню в компонент
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	//****

	//открываем форму в режиме изменения
	const handleOpenEdit = item => {
		console.log('edit meal item');
		setCurrentMealContent({
			id: item.id,
			fid: item.fid,
			pid: item.pid,
			name: item.name,
			k: item.k,
			b: item.b,
			z: item.z,
			u: item.u,
			amount: item.amount,
			comment: item.comment,
			status: item.status
		})
		setEditing(true)
	}

	//открываем форму в режиме добавления
	const handleOpenAdd = () => {
		console.log('add meal item');
		setCurrentMealContent(defaultMealContent);
		setEditing(true);
	}

	//
	const handleTestTotals = value => {
		console.log('set test totals',value);
		setTotals(value);
	}

	/*
	useEffect(()=>{
		console.log('totals changed=',totals);
	}, [totals]);
	*/

	//загрузка всех частей
	useEffect(()=>{
		if (!props.match.params.date) return;
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealpart';
		if (props.match.params.date) {
			url += '/'+props.match.params.date;
		}
		fetch(url)
			.then((res) => res.json())
			.then((json) => {
				json.mealparts.map(part=>{
					part.name = PartTypesList[part.mid];
					if (props.match.params.eating && part.id === props.match.params.eating) {
						setCurrentFid(Number(props.match.params.eating));
						setCurrentFidInfo(part);
					}
					return part;
				});
				setParts(json.mealparts);
				console.log(json.mealparts);
			})
			.catch((e) => {
				console.error(e);
			});
	},[]);//один раз при загрузке, т.к. зависит от пустого []


	let history = useHistory();
	const actionDeletePart = () => {
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealpart';
		url=url+'/'+currentFid;
		fetch(url,{
			method: "POST",
			body: JSON.stringify({
				delete: true
			})
		})
			.then((res) => res.json())
			.then((json) => {
				history.push("/meal/"+props.match.params.date);
			})
			.catch((e) => {
				setError("Ошибка. Попробуйте позднее");
				console.error(e);
			});
	};

	//загрузка еды по текущей части
	useEffect(() => {
		if (!props.match.params.date || !props.match.params.eating) {
			return;
		}
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealcontent';
		url=url+'/'+props.match.params.date+'/'+props.match.params.eating;
		fetch(url)
			.then((res) => res.json())
			.then((json) => {
				setMealContentList(json.mealcontents)
			})
			.catch((e) => {
				setError("Ошибка загрузки. Попробуйте позднее");
				console.error(e);
			});

		/****
		if (!props.match.params.date) {
			return;
		}
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealday';
		url=url+'/'+props.match.params.date;
		 */
		let url_mealday = api_root+'mealday'+'/'+props.match.params.date;
		fetch(url_mealday)
			.then((res) => res.json())
			.then((json) => {
				setDayinfo(json.mealday)
			})
			.catch((e) => {
				setError("Ошибка загрузки. Попробуйте позднее");
				console.error(e);
			});
	}, []);//один раз при загрузке, т.к. зависит от пустого []

	//TODO fox - сохранять итоговые КБЖУ в day на сервере
	//TODO fox - сохранять итоговые КБЖУ в part на сервере
	//TODO hawk - "Изменить время" - вызывать блок с таймпикером
	return (
		<div className="app-page">
			{!editing ? (
				<TopBarContext href={backUrl} title={(currentFidInfo && currentFidInfo.name||'')+" - "+(currentFidInfo && currentFidInfo.time||'')}>
					<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleCloseMenu}
					>
						<MenuItem onClick={handleCloseMenu}>Изменить время</MenuItem>
						<MenuItem onClick={handleCloseMenu}>Копировать из другого дня</MenuItem>
						<MenuItem onClick={()=>actionDeletePart(currentFid)}>Удалить</MenuItem>
						<MenuItem onClick={handleCloseMenu}>Рандом</MenuItem>
					</Menu>
				</TopBarContext>
			) : (
				<TopBarContext onClose={handleCloseEditing} title="Съесть" />
			)}
			<div className="single-wrap">
				<KbzuTotal day={dayinfo} total={totals} showCorridor={false}/>
				{/* currentDate */}
			</div>
			<div className={!editing ? ("app-content app-content--sheet pdt-0") : ("app-content app-content--sheet")}>
				{mealContentList ?
					!editing && (
						<MealContentList items={mealContentList}
										 onEdit={handleOpenEdit}
										 onSelect={markRow}
										 onDelete={deleteRow}/>
					)
				: !error ? (
						<FuiLoader />
				) : null}
				{editing && (
					<AddMealContent onClose={handleCloseEditing}
									onSubmit={saveRow}
									parts={parts}
									totals={totals}
									onTotalsChange={handleTestTotals}
									currentItem={currentMealContent}
									onDelete={deleteRow}/>
				)}
				{!editing && (
					<FuiFab setClick={handleOpenAdd} />
				)}
			</div>
		</div>
	)
}