import React from 'react'
import Alert from "@material-ui/lab/Alert";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";

export const ProductList = props => {
	/*
	const handleDeleteProduct = id => {
		let answer = window.confirm('Are you sure?')

		if (answer) {
			props.deleteProduct(id)
		}
	}
	//<button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
	*/
	return props.items && props.items.length > 0 ? (
		<List className="fui-list-bordered" component="div">
			{props.items.map(product => (
				<ListItem key={product.id} button onClick={() => {
					props.editRow(product)
				}}>
					<ListItemText
						primary={
							<React.Fragment>
								<span className="fui-list-name">
									<span className="fui-list-name__value">
										{product.name}
									</span>
								</span>
							</React.Fragment>
						}
						secondary={
							<React.Fragment>
							<span className="food-data">
								<span className="food-data__left">
									<span className="food-data__item">Б: {product.b}</span>
									<span className="food-data__item">Ж: {product.z}</span>
									<span className="food-data__item">У: {product.u}</span>
								</span>
								<span className="food-data__right">
									<span className="food-data__item">{product.k} ккал</span>
								</span>
							</span>
							</React.Fragment>
						}
					/>
				</ListItem>
			))}
		</List>
	) : (
		<Alert severity="info" variant="outlined">Нет данных</Alert>
	)
}

/*
function ProductItem(props) {
	const product = props.product
	return (<ListItem button id={product.id} onClick={()=>{props.onItemSelect(product.id)}}>
		<ListItemText
			primary={product.name}
			secondary={
				<React.Fragment>
					<span className="food-data">
						<span className="food-data__left">
							<span className="food-data__item">Б: {product.b}</span>
							<span className="food-data__item">Ж: {product.z}</span>
							<span className="food-data__item">У: {product.u}</span>
						</span>
						<span className="food-data__right">
							<span className="food-data__item">{product.k} ккал</span>
						</span>
					</span>
				</React.Fragment>
			}
		/>
	</ListItem>)
}

<ProductItem key={product.id} product={product} onItemSelect={onProductSelect}/>
*/