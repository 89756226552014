import React, {useEffect, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import Skeleton from '@material-ui/lab/Skeleton';
import Moment from 'react-moment';
import 'moment/locale/ru';//TODO move to global
Moment.globalLocale = 'ru';//TODO move to global';

function getMonday(d) {
	d = new Date(d);
	const day = d.getDay(),
		diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
	return new Date(d.setDate(diff));
}

Date.prototype.yyyymmdd = function() {
	const mm = this.getMonth() + 1; // getMonth() is zero-based
	const dd = this.getDate();

	return [this.getFullYear(),
		(mm>9 ? '' : '0') + mm,
		(dd>9 ? '' : '0') + dd
	].join('-');
};

function getThisWeek(d,monday) {
	const weekDayNames = [
		'вс','пн','вт','ср','чт','пт','сб','вс'
	];
	let cur;
	if (d) {
		cur = new Date(d);
	}else{
		cur = new Date();
	}
	//console.log(cur);
	let start = -3, cnt = 7, sel_on_pos = 3;
	if (monday) {
		let day = cur.getDay();
		let diff = (day === 0 ? -6:1) - day;// до понедельника
		start = diff;
		sel_on_pos = -diff;
	}
	let res = [];
	cur.setDate(cur.getDate() + start);
	for (var i=0;i<cnt;i++) {
		res.push({
			value: cur.yyyymmdd(),
			daynum: cur.getDate(),
			weekday: weekDayNames[cur.getDay()],
			selected: (i===sel_on_pos)
		});
		cur.setDate(cur.getDate() + 1);
	}
	return res;
}

function WeekCalendar(props) {
	const [curWeek,setCurWeek] = useState([]);
	useEffect(()=>{
		setCurWeek(getThisWeek(props.date,props.startFromMonday));
	},[props]);
	const selectDate = date => {
		console.log(date);
		props.onSelect(date);
	}
	//<button onClick={() => handleDeleteProduct(product.id)}>Delete</button>

	//<Moment date={day.value} format="YYYY-MM-DD" />
	//{weekDayNames[day.date.getDay()]}
	//.getDate()
	return curWeek && curWeek.length > 0 ? (
		<div className="week-calendar">
			<div className="week-calendar__days">
				{curWeek.map((day,index) => (
				<div className="week-calendar__col" key={index} onClick={()=>selectDate(day.value)}>
					<div className={"week-calendar__day "+(day.selected ? "state-active":"")}>
						<div className="week-calendar__day-name">{day.weekday}</div>
						<IconButton>{day.daynum}</IconButton>
					</div>
				</div>
				))}
			</div>
			<div className="week-calendar__month"><Moment date={props.date} format="MMMM YYYY" /></div>
		</div>
	) : (
		<div className="week-calendar week-calendar--skeleton">
			<div className="week-calendar__days">
				<div className="week-calendar__col">
					<div className="week-calendar__day">
						<div className="week-calendar__day-name"><Skeleton variant="text" width={16} height={14} /></div>
						<Skeleton variant="circle" width={38} height={38} />
					</div>
				</div>
				<div className="week-calendar__col">
					<div className="week-calendar__day">
						<div className="week-calendar__day-name"><Skeleton variant="text" width={16} height={14} /></div>
						<Skeleton variant="circle" width={38} height={38} />
					</div>
				</div>
				<div className="week-calendar__col">
					<div className="week-calendar__day">
						<div className="week-calendar__day-name"><Skeleton variant="text" width={16} height={14} /></div>
						<Skeleton variant="circle" width={38} height={38} />
					</div>
				</div>
				<div className="week-calendar__col">
					<div className="week-calendar__day">
						<div className="week-calendar__day-name"><Skeleton variant="text" width={16} height={14} /></div>
						<Skeleton variant="circle" width={38} height={38} />
					</div>
				</div>
				<div className="week-calendar__col">
					<div className="week-calendar__day">
						<div className="week-calendar__day-name"><Skeleton variant="text" width={16} height={14} /></div>
						<Skeleton variant="circle" width={38} height={38} />
					</div>
				</div>
				<div className="week-calendar__col">
					<div className="week-calendar__day">
						<div className="week-calendar__day-name"><Skeleton variant="text" width={16} height={14} /></div>
						<Skeleton variant="circle" width={38} height={38} />
					</div>
				</div>
				<div className="week-calendar__col">
					<div className="week-calendar__day">
						<div className="week-calendar__day-name"><Skeleton variant="text" width={16} height={14} /></div>
						<Skeleton variant="circle" width={38} height={38} />
					</div>
				</div>
			</div>
			<div className="week-calendar__month"><Skeleton variant="text" width={76} height={14} /></div>
		</div>
	);
}

export default WeekCalendar;