import React from 'react';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

class FuiFab extends React.Component {

	constructor(props) {
		super(props);
		this.handleClick = props.setClick
		this.state = {
			isBottom: false
		};
		this.handleScroll = this.handleScroll.bind(this);
	}

	handleScroll() {
		const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
		const body = document.body;
		const html = document.documentElement;
		const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
		const windowBottom = windowHeight + window.pageYOffset;
		if (windowBottom >= docHeight) {
			this.setState({
				isBottom: true
			});
		} else {
			this.setState({
				isBottom: false
			});
		}
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	render() {
		const scrollStatus = this.state.isBottom
		return (
			<div className="fui-fab-control">
				{scrollStatus>=1 ? (
					<Button onClick={this.handleClick} variant="contained">Добавить</Button>
				) : (
					<Fab onClick={this.handleClick} className="fab" aria-label="Добавить" >
						<AddIcon />
					</Fab>
				)
				}

			</div>
		);
	}
}

FuiFab.propTypes = {
}

FuiFab.defaultProps = {
}

export default FuiFab;