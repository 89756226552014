import React, {useEffect, useState} from 'react'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {AddMealContentProductDialog} from "../meal/AddMealContentProductDialog";

const AddMealContent = props => {
    //props.part_total
    //curtotal.k = total.k - part.k ????
    //props.part_id
    //props.meal_id
    //props.meal
    const [showProductDialog, setProductShowDialog] = useState(false)
    const [item,setItem] = useState(props.currentItem);

    // используем effect-hook
    useEffect(
        () => {
            // вызывай данную функцию
            setItem(props.currentItem)
        },
        [props.currentItem] // всегда, если изменились props
    )

    const handleInputChange = event => {
        let {name, value} = event.target
        //if (name==="pid" && value) setError(false);
        if (name==='amount' || name==='pid' || name==='fid') {
            value = Number(value);
            if (typeof value !== "number") value=0;
        }
        setItem({...item, [name]: value})
        //if (name==='fid') props.onTotalsChange({k: 10000, b: 10, z: 10, u: 10});
    }
    const handleCloseProductDialog = () => {
        setTimeout(()=> setProductShowDialog(false),300);
    };
    const handleChangeProduct = product => {
        //подставить в форму
        setItem({...item,
            name:product.name,
            pid:product.id,
            comment:product.comment,
            k:product.k,
            b:product.b,
            z:product.z,
            u:product.u
        })
        setTimeout(()=> setProductShowDialog(false),300);
    };
    const handleSubmit = () => {
        console.log('save meal');
        props.onSubmit(item);
        props.onClose()
    }
    //обновляем светофор
    let [localTotals,setLocalTotals] = useState(null);
    useEffect(()=>{
        let prev = props.currentItem;
        let t = { k: 0, b: 0, z: 0, u: 0 }
        t.k = props.totals.k - prev.k*prev.amount*0.01;
        t.b = props.totals.b - prev.b*prev.amount*0.01;
        t.z = props.totals.z - prev.z*prev.amount*0.01;
        t.u = props.totals.u - prev.u*prev.amount*0.01;
        setLocalTotals(t);
    },[]);
    useEffect(()=>{
        if (localTotals) {
            let t = { k: 0, b: 0, z: 0, u: 0 }
            console.log(t);
            t.k = localTotals.k + item.k * item.amount * 0.01;
            t.b = localTotals.b + item.b * item.amount * 0.01;
            t.z = localTotals.z + item.z * item.amount * 0.01;
            t.u = localTotals.u + item.u * item.amount * 0.01;
            t.k = Number(t.k.toFixed(0));
            t.b = Number(t.b.toFixed(1));
            t.z = Number(t.z.toFixed(1));
            t.u = Number(t.u.toFixed(1));
            //setLocalTotals(t);
            props.onTotalsChange(t);
        }
    },[item,localTotals]);
    const handleDelete = () => {
        let answer = window.confirm('Удалить продукт?')
        if (answer) {
            props.onDelete(item.id);
            props.onClose()
        }
    };
    //FIXME не работает выбор приема пищи
    //TODO прием пищи выводить через props.parts (текущий список частей за день)
    return (
        <div>
        <form className="fui-data-form" onSubmit={handleSubmit}>
            <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Прием пищи</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="fid"
                    value={item.fid}
                    onChange={handleInputChange}
                    label="Прием пищи"
                >
                    {props.parts.map(part => (
                        <MenuItem value={part.id} key={part.id}>{part.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="select-product-control">
                <input type="hidden" name="pid" value={item.pid} onChange={handleInputChange} />
                <TextField onClick={()=>setProductShowDialog(true)}
                           InputProps={{readOnly: true,}}
                           name="name" label="Продукт" variant="outlined"
                           value={item.name} onChange={handleInputChange}
                />
            </div>
            <div className="amount-control">
                <TextField name="amount" value={item.amount} onChange={handleInputChange}
                           inputProps={{ inputMode: 'numeric' }}
                           label="Вес" variant="outlined" />
                <div className="amount-control__comment">{item.comment}</div>
            </div>
            <div className="food-data mt-24">
                <span className="food-data__left">
                    <span className="food-data__item">Б: {(item.b*item.amount*0.01).toFixed(1)}</span>
                    <span className="food-data__item">Ж: {(item.z*item.amount*0.01).toFixed(1)}</span>
                    <span className="food-data__item">У: {(item.u*item.amount*0.01).toFixed(1)}</span>
                </span>
                <span className="food-data__right">
                    <span className="food-data__item">{(item.k*item.amount*0.01).toFixed(0)} ккал</span>
                </span>
            </div>
            {item.id ? (
                <div className="fui-drawer-bottom fui-drawer-bottom--2actions">
                    <Button variant="outlined" onClick={handleDelete}>Удалить</Button>
                    <Button variant="contained" type="submit" disabled={!item.pid}>Сохранить</Button>
                </div>
            ) : (
                <div className="fui-drawer-bottom">
                    <Button variant="contained" type="submit" disabled={!item.pid}>Добавить</Button>
                </div>
            )}
        </form>
        {showProductDialog && (
            <AddMealContentProductDialog onClose={handleCloseProductDialog} onSubmit={handleChangeProduct} />
        )}
        </div>
    )
}

export { AddMealContent }
