import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {FuiLoader} from "../common";
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";

const Transition = React.forwardRef(function Transition(props, ref) {
	//return <Slide direction="up" ref={ref} {...props} />;
	//return <Zoom in="true" ref={ref} {...props} />;
	return <Fade in="true" ref={ref} {...props} />;
});

export default function (props) {
	const initialFormState = {
		id: null,
		chest: '',
		waist: '',
		thigh: '',
		biceps: '',
		underChest: '',
		thighTop: '',
	}
	const [volume, setVolume] = useState(initialFormState)
	const [error, setError] = useState('');
	const [inputNameError, setInputNameError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleInputChange = event => {
		const {name, value} = event.currentTarget;
		if (name==="name" && value) setInputNameError(false);
		setVolume({...volume, [name]: value})
	}

	const [open, setOpen] = useState(true);
	const handleSubmit = event => {
		event.preventDefault()
		if (!volume.name) {
			setInputNameError({error:"Пустое название продукта"});
			return;
		}

		//обновление на сервере
		setLoading(true);
		let api_root = process.env.REACT_APP_API_ROOT;
		if (0 && process.env.NODE_ENV === "development") {
			api_root = '/api/';
		}
		let url = api_root+'volumes';
		if (volume.id) {
			url += volume.id;
		}
		fetch(url, {
			method: "POST",
			body: JSON.stringify(volume)
		})
			.then((res) => res.json())
			.then((json) => {
				// вызываем addvolume из хука из App
				props.addvolume(volume)
				// обнуляем форму, с помощью setVolume функции
				// которая у нас взята из хука в данном компоненте [1]
				setVolume(initialFormState)
				//закрыть диалог
				handleClose();
			})
			.catch((e) => {
				setError("Ошибка. Попробуйте позднее");
				console.error(e);
			})
			.finally(() => {
				setLoading(false)
			});
	}
	const handleClose = () => {
		setOpen(false);
		props.onClose();
	};

	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar elevation={2}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6">
						Объемы
					</Typography>
				</Toolbar>
			</AppBar>
			<div className="dialog-content">
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<div className="fui-data-form">
						<TextField name="chestVolume" label="Объем груди см" variant="outlined"
						           value={volume.chest} onChange={handleInputChange}
						           error={!inputNameError} helperText={inputNameError}
						/>
						<TextField name="waistVolume" label="Объем талии см" variant="outlined"
						           inputProps={{ inputMode: 'numeric' }}
						           value={volume.waist} onChange={handleInputChange}/>
						<TextField name="b" label="Объем бедер см" variant="outlined"
						           inputProps={{ inputMode: 'numeric' }}
						           value={volume.thigh} onChange={handleInputChange}/>
						<TextField name="z" label="Объем бицепса, см" variant="outlined"
						           inputProps={{ inputMode: 'numeric' }}
						           value={volume.biceps} onChange={handleInputChange}/>
						<TextField name="u" label="Объем под грудью, см" variant="outlined"
						           inputProps={{ inputMode: 'numeric' }}
						           value={volume.underChest} onChange={handleInputChange}/>
						<TextField name="comment" label="Объем бедра вверху, см" variant="outlined"
						           value={volume.thighTop} onChange={handleInputChange}/>
						<div className="fui-drawer-bottom">
							<Button variant="contained" type="submit">Сохранить</Button>
						</div>
						{error && <Alert severity="error">{error}</Alert>}
						{loading && <FuiLoader />}
					</div>
				</form>
			</div>
		</Dialog>
	);
}