import React from "react";
import TopBar from "../../components/common/TopBar/TopBar";
import {AuthButton} from "../../components/common/Auth/Auth";

export default function AccountPage() {
	return (
		<div className="app-page">
			<TopBar title="Аккаунт" />
			<div className="app-content">
				<AuthButton/>
			</div>
		</div>
	);
}
