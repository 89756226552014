import React, {useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import {FuiLoader} from "../common";
import CloseIcon from "@material-ui/icons/Close";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
    //return <Zoom in="true" ref={ref} {...props} />;
    //return <Fade in="true" ref={ref} {...props} />;
});

const CorridorFormCalc = props => {
    const [person, setPerson] = useState(props.corParams);
    const [error, setError] = useState('');
    const [inputNameError, setInputNameError] = useState('');
    const [loading, setLoading] = useState(false);

    // используем effect-hook
    useEffect(
        () => {
            // вызывай данную функцию
            setPerson(props.corParams)
        },
        [props] // всегда, если изменились props
    )

    const handleInputChange = event => {
        const {name, value} = event.target
        if (name==="name" && value) setInputNameError(false);
        setPerson({...person, [name]: value})
    }

    const [open, setOpen] = useState(false);
    useEffect(()=>{
        setOpen(props.show);
    },[props.show]);
    const handleSubmit = event => {
        event.preventDefault()
        /*
        if (!person.weight) {
            setInputNameError({error:"Укажите вес"});
            return;
        }
         */
        //обновление на сервере
        setLoading(true);
        let api_root = process.env.REACT_APP_API_ROOT;
        if (0 && process.env.NODE_ENV === "development") {
            api_root = '/fakeapi/';
        }
        let url = api_root+'calculate';
        fetch(url, {
            method: "POST",
            body: JSON.stringify({...person,day:props.day})
        })
            .then((res) => res.json())
            .then((json) => {
                //обновление в списке (если есть)
                props.onSave(person,json.corridor);
                //закрыть диалог
                handleClose();
            })
            .catch((e) => {
                setError("Ошибка. Попробуйте позднее");
                console.error(e);
            })
            .finally(() => {
                setLoading(false)
            });
    }
    const handleClose = () => {
        setOpen(false);
        props.afterClose();
    };
    if (!props.show) return '';
    return (
        <Dialog fullScreen open={open} onClose={handleClose}
                TransitionComponent={Transition} closeAfterTransition={true}>
            <AppBar elevation={2}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Изменить продукт
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="dialog-content">
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="fui-data-form">
                        <div className="choice-control mb-24">
                            <input id="gender1" name="gender" value="F" type="radio" />
                            <label htmlFor="gender1" className="choice-control__option">Женский</label>
                            <input id="gender2" name="gender" value="M" type="radio"/>
                            <label htmlFor="gender2" className="choice-control__option">Мужской</label>
                            <div className="choice-control__selected-marker"></div>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField name="height" label="рост" variant="outlined"
                                           value={person.height} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="age" label="Возраст, лет" variant="outlined"
                                           value={person.age} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="weight" label="вес" variant="outlined"
                                           value={person.weight} onChange={handleInputChange}/>
                            </Grid>
                        </Grid>
                        <div className="choice-control mb-8 mt-24">
                            <input id="target1" name="direction" value="down" type="radio" />
                            <label htmlFor="target1" className="choice-control__option">Снижение веса</label>
                            <input id="target2" name="direction" value="up" type="radio"/>
                            <label htmlFor="target2" className="choice-control__option">Набор веса</label>
                            <div className="choice-control__selected-marker"></div>
                        </div>
                        <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">Скорость изменения веса</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="speed"
                                value={person.speed}
                                onChange={handleInputChange}
                                label="Скорость изменения веса"
                            >
                                <MenuItem value={1}>Поддержание текущего веса</MenuItem>
                                <MenuItem value={2}>Умеренное снижение веса</MenuItem>
                                <MenuItem value={3}>Быстрое снижение веса</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">Уровень активности</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="activity"
                                value={person.activity}
                                onChange={handleInputChange}
                                label="Уровень активности"
                            >
                                <MenuItem value={1}>Низкий (сидячий образ жизни)</MenuItem>
                                <MenuItem value={2}>Средний (2-3 тренировки в неделю)</MenuItem>
                                <MenuItem value={3}>Высокий (ежедневные тренировки)</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">Система питания (Баланс БЖУ)</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="system"
                                value={person.system}
                                onChange={handleInputChange}
                                label="Система питания (Баланс БЖУ)"
                            >
                                <MenuItem value={1}>Сбалансированная 1 (30/20/50)</MenuItem>
                                <MenuItem value={2}>Другая</MenuItem>
                                <MenuItem value={3}>Рандом</MenuItem>
                            </Select>
                        </FormControl>
                        <div className="fui-drawer-bottom fui-drawer-bottom--2actions">
                            <Button variant="contained" type="submit">Рассчитать</Button>
                        </div>
                        {error && <Alert  severity="error">{error}</Alert>}
                        {loading && <FuiLoader />}
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default CorridorFormCalc
