import React, {useEffect, useState} from "react";
import {withFirebase} from '../fire/';
import Paper from '@material-ui/core/Paper';

//import * as firebase from "firebase";

const FuiFire = function (props) {

    console.log(props);

    const [displayToken, setDisplayToken] = useState();
    const [displayPushPermission, setDisplayPushPermission] = useState(false);
    const [messages, setMessages] = useState([]);

    /*
    setMessages([
        {
            item:{a:1,b:1}
        },
        {
            item:{a:2,b:2}
        },
        {
            item:{a:3,b:3}
        },
    ]);*/

    const canPush = props.firebase.canPush;

    const messaging = canPush && props.firebase.app.messaging();

    const conf = props.firebase.messaging_config;

    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    /*
    messaging.getToken(conf).then((currentToken) => {
        if (currentToken) {
            sendTokenToServer(currentToken);
            updateUIForPushEnabled(currentToken);
        } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
            // Show permission UI.
            updateUIForPushPermissionRequired();
            setTokenSentToServer(false);
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        showToken('Error retrieving Instance ID token. ', err);
        setTokenSentToServer(false);
    });
    */

    // [START refresh_token]
    // Callback fired if Instance ID token is updated.
/*
    messaging.getToken(conf).then((refreshedToken) => {
        console.log('Token refreshed.');
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        setTokenSentToServer(false);
        // Send Instance ID token to app server.
        sendTokenToServer(refreshedToken);
        // [START_EXCLUDE]
        // Display new Instance ID token and clear UI of all previous messages.
        resetUI();
        // [END_EXCLUDE]
    }).catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
        showToken('Unable to retrieve refreshed token ', err);
    });*/

    // [END refresh_token]

    // [START receive_message]
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.setBackgroundMessageHandler` handler.
    canPush && messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        // [START_EXCLUDE]
        // Update the UI to include the received message.
        appendMessage(payload);
        // [END_EXCLUDE]
    });
    // [END receive_message]


    function resetUI() {
        if (!canPush) return false;
        clearMessages();
        showToken('loading...');
        // [START get_token]
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging.getToken(conf).then((currentToken) => {
            if (currentToken) {
                sendTokenToServer(currentToken);
                updateUIForPushEnabled(currentToken);
            } else {
                // Show permission request.
                console.log('No Instance ID token available. Request permission to generate one.');
                // Show permission UI.
                updateUIForPushPermissionRequired();
                setTokenSentToServer(false);
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            showToken('Error retrieving Instance ID token. ', err);
            setTokenSentToServer(false);
        });
        // [END get_token]
    }

     function showToken(currentToken) {
        // Show token in console and UI.
        setDisplayToken(currentToken);
    }

    function sendTokenToServer(currentToken) {
        if (!isTokenSentToServer()) {
            console.log('Sending token to server...');
            // TODO(developer): Send the current token to your server.
            setTokenSentToServer(true);
        } else {
            console.log('Token already sent to server so won\'t send it again ' +
                'unless it changes');
        }

    }

    function isTokenSentToServer() {
        return window.localStorage.getItem('sentToServer') === '1';
    }

    function setTokenSentToServer(sent) {
        window.localStorage.setItem('sentToServer', sent ? '1' : '0');
    }

    /*
    function requestPermission() {
        console.log('Requesting permission...');
        // [START request_permission]
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                // TODO(developer): Retrieve an Instance ID token for use with FCM.
                // [START_EXCLUDE]
                // In many cases once an app has been granted notification permission,
                // it should update its UI reflecting this.
                resetUI();
                // [END_EXCLUDE]
            } else {
                console.log('Unable to get permission to notify.');
            }
        });
        // [END request_permission]
    }
    */

    function deleteToken() {
        if (!canPush) return false;
        // Delete Instance ID token.
        // [START delete_token]
        messaging.deleteToken().then(function () {
            console.log('Token deleted.');
            setTokenSentToServer(false);
            // [START_EXCLUDE]
            // Once token is deleted update UI.
            resetUI();
            // [END_EXCLUDE]
        }).catch(function (err) {
            console.log('Unable to delete token. ', err);
        });
    }

    // Add a message to the messages element.
    function appendMessage(payload) {

        setMessages([...messages,{
            title:payload.notification.title,
            body:payload.notification.body,
        }]);
        //window.console.log(payload);
    }

    // Clear the messages element of all children.
    function clearMessages() {
        setMessages([])
    }

    function updateUIForPushEnabled(currentToken) {
        setDisplayPushPermission(false);
        showToken(currentToken);
    }

    function updateUIForPushPermissionRequired() {
        setDisplayToken(false);
        setDisplayPushPermission(true);
    }

    useEffect(()=>{
        if (!canPush) return false;
        if (!isTokenSentToServer()) {
            resetUI()
        }
    },[])


    function testMessage() {
        setMessages([...messages,{
            title:'test title',
            body:'some useless text in message',
            image:'https://spa.smartfox-cms.ru/logo192.png'
        }]);
    }

    if (!canPush) return null;

    return (
        <Paper className="pd-16" elevation={1}>
            <div>FUI firebase</div>
            <br/>
            <button onClick={resetUI}>start</button>
            <br/>
            <button onClick={testMessage}>test message</button>
            <br/>
            {displayToken && (
                <div>
                    <div>{displayToken}</div>
                    <button onClick={deleteToken}>Delete token</button>
                </div>
            )}
            <br/>
            {displayPushPermission && (
                <div>
                    <div>{displayPushPermission}</div>
                    <div>{displayToken}</div>
                    <button>Request Permission</button>
                </div>
            )}
            <br/>
            {messages.map((message,index) => (
                <div key={index}>
                    <h5>Message: {message.title}</h5>
                    <div>{message.body}</div>
                    {message.image && (<img src={message.image} width="50" height="50"/>)}
                </div>
            ))}
        </Paper>
    );

}
/*
<FirebaseContext.Consumer>
                {firebase => {
                    return <div>I've access to Firebase and render something.</div>;
                }}
            </FirebaseContext.Consumer>
 */

export default withFirebase(FuiFire);