import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Link as RouterLink} from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

function WaterPanel(props) {
	const[water, setWater] = useState(props.pars.amount);
	const progressCalc = water*100/props.pars.target
	const[waterProgress, setWaterProgress] = useState(progressCalc);
	const handleStatus = value => {
		setWater(water+value)
		setWaterProgress((water+value)*100/props.pars.target)
	}
	return (
		<Paper className="fui-water-panel" elevation={1}>
			<div className="fui-water-panel__top">
				<div className="fui-water-panel__amount">
					<span className="fui-water-panel__amount-value">{water}</span>мл. воды выпито
				</div>
				<div className="fui-water-panel__target">{props.pars.target}</div>
			</div>
			<LinearProgress variant="determinate" value={waterProgress} />
			<div className="fui-water-panel__actions">
				<div>
					<Chip label="+100" onClick={() => handleStatus(100)} variant="outlined" />
					<Chip label="+200" onClick={() => handleStatus(150)} variant="outlined" />
					<Chip label="+250" onClick={() => handleStatus(250)} variant="outlined" />
				</div>
				<div>
					<IconButton component={RouterLink} to="/water/"><ArrowForwardIcon /></IconButton>
				</div>
			</div>
		</Paper>
	);
}

export default WaterPanel;