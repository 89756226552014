import React, {useEffect, useState} from "react";
import DoneIcon from '@material-ui/icons/Done';

const KbzuTotal = props => {
	const [diff, setDiff] = useState({});
	useEffect(()=>{
		let d = {
			k: 0,
			b: 0,
			z: 0,
			u: 0
		}
		if (props.total.k < props.day.k1) {
			d.k = props.day.k1 - props.total.k;
		} else if (props.total.k > props.day.k2) {
			d.k = props.day.k2 - props.total.k;
		}

		if (props.total.b < props.day.b1) {
			d.b = props.day.b1 - props.total.b;
		} else if (props.total.b > props.day.b2) {
			d.b = props.day.b2 - props.total.b;
		}

		if (props.total.z < props.day.z1) {
			d.z = props.day.z1 - props.total.z;
		} else if (props.total.z > props.day.z2) {
			d.z = props.day.z2 - props.total.z;
		}

		if (props.total.u < props.day.u1) {
			d.u = props.day.u1 - props.total.u;
		} else if (props.total.u > props.day.u2) {
			d.u = props.day.u2 - props.total.u;
		}

		d.k = +d.k.toFixed(0);
		d.b = +d.b.toFixed(1);
		d.z = +d.z.toFixed(1);
		d.u = +d.u.toFixed(1);

		setDiff(d);
	},[props]);
	let showCorridor = props.showCorridor;
	return (
		<div className="kbzu-total">
			<div className={"kbzu-total__item "+(diff.b ? (diff.b>0?"state-warning":"state-danger") : "state-good")}>
				<div className="kbzu-total__item-top">
					<div className="kbzu-total__item-name">Б</div>
					<div className="kbzu-total__item-diff">
						{diff.b ? (
							diff.b>0?'+'+diff.b:diff.b
						) :
						(
							<DoneIcon />
						)}
					</div>
				</div>
				<div className="kbzu-total__item-value">{props.total.b.toFixed(1)}</div>
				{showCorridor && (<div className="kbzu-total__item-value">{props.day.b1} / {props.day.b2}</div>)}
			</div>
			<div className={"kbzu-total__item "+(diff.z ? (diff.z>0?"state-warning":"state-danger") : "state-good")}>
				<div className="kbzu-total__item-top">
					<div className="kbzu-total__item-name">Ж</div>
					<div className="kbzu-total__item-diff">
						{diff.z ? (
							diff.z>0?'+'+diff.z:diff.z
						) :
						(
							<DoneIcon />
						)}
					</div>
				</div>
				<div className="kbzu-total__item-value">{props.total.z.toFixed(1)}</div>
				{showCorridor && (<div className="kbzu-total__item-value">{props.day.z1} / {props.day.z2}</div>)}
			</div>
			<div className={"kbzu-total__item "+(diff.u ? (diff.u>0?"state-warning":"state-danger") : "state-good")}>
				<div className="kbzu-total__item-top">
					<div className="kbzu-total__item-name">У</div>
					<div className="kbzu-total__item-diff">
						{diff.u ? (
								diff.u>0?'+'+diff.u:diff.u
							) :
							(
								<DoneIcon />
							)}
					</div>
				</div>
				<div className="kbzu-total__item-value">{props.total.u.toFixed(1)}</div>
				{showCorridor && (<div className="kbzu-total__item-value">{props.day.u1} / {props.day.u2}</div>)}
			</div>
			<div className={"kbzu-total__item "+(diff.k ? (diff.k>0?"state-warning":"state-danger") : "state-good")}>
				<div className="kbzu-total__item-top">
					<div className="kbzu-total__item-name">К</div>
					<div className="kbzu-total__item-diff">{diff.k ? (
							diff.k>0?'+'+diff.k:diff.k
						) :
						(
							<DoneIcon />
						)}</div>
				</div>
				<div className="kbzu-total__item-value">{props.total.k.toFixed(0)}</div>
				{showCorridor && (<div className="kbzu-total__item-value">{props.day.k1} / {props.day.k2}</div>)}
			</div>
		</div>
	);
}

export default KbzuTotal;