import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/analytics';

/*
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};*/
const config = {
    apiKey: "AIzaSyDVzl-wl3b5LqTjSm9mOj_4N_b54IVsFxA",
    authDomain: "fattrial.firebaseapp.com",
    databaseURL: "https://fattrial.firebaseio.com",
    projectId: "fattrial",
    storageBucket: "fattrial.appspot.com",
    messagingSenderId: "538489570778",
    appId: "1:538489570778:web:546b4ce971fb7255ff6ce3",
    measurementId: "G-3VK0E124YD"
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        app.analytics()

        this.auth = app.auth();
        this.app = app;
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));
        this.canPush = !isSafari;
        this.messaging = this.canPush && app.messaging();
        this.messaging_config = {
            vapidKey: "BNiFKJfsUayNBm5hVrThdhOEmEYwe_Jn6OCUP3b6E95P-cw-fpU0RtPqrS-K2wg5JZ1EloUjQTanQFBCJ7LH8mM"
        }
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);
}

export default Firebase;