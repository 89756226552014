import React, {useState} from "react";
import TopBarContext from "../../components/common/TopBarContext/TopBarContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import WaterWaves from "../../assets/images/water-waves.png"
import WaterLine from "../../assets/images/water-line.svg"
import Alert from '@material-ui/lab/Alert';

export default function WaterPage() {
	const waterPars = {
		amount: 0,
		target: 2500,
	}
	const[water, setWater] = useState(waterPars.amount);
	const progressCalc = water*100/waterPars.target
	const[waterProgress, setWaterProgress] = useState(progressCalc);
	const waterOverTarget = water - waterPars.target
	const handleStatus = value => {
		setWater(water+value)
		setWaterProgress((water+value)*100/waterPars.target)
	}
	return (
		<div className="app-page app-page--water">
			<TopBarContext title="Трекер воды" />
			<div className="app-content app-content--place-at-bottom">
				<div className="water-visual-status">
					<div className="water-visual-status__marker" style={{height: waterProgress + '%'}}>
						<img src={WaterWaves} alt=""/>
					</div>
				</div>
				<div className="water-visual-target">
					<div className="water-visual-target__info">
						<div className="water-visual-target__title">Ваша цель</div>
						<div className="water-visual-target__value">{waterPars.target}</div>
					</div>
					<img src={WaterLine} width={32} height={17} alt=""/>
				</div>
				<div className="water-info">
					{water > waterPars.target && (
						<Alert severity="warning">вы превысили цель на  {waterOverTarget} мл</Alert>
					)}
					<div className="water-info__amount">
						<div className="water-info__amount-label">Выпито</div>
						<div className="water-info__amount-value">{water}</div>
					</div>
					<TextField name="water-amount" label="Выпить, мл" variant="outlined" inputProps={{ inputMode: 'numeric' }} value="" />
					<div className="water-info__actions">
						<Chip label="+100" onClick={() => handleStatus(100)} variant="outlined" />
						<Chip label="+200" onClick={() => handleStatus(150)} variant="outlined" />
						<Chip label="+250" onClick={() => handleStatus(250)} variant="outlined" />
					</div>
					<Button className="water-info__submit" variant="contained">Выпить</Button>
				</div>
			</div>
		</div>
	);
}
