import React from 'react';
import BottomBar from "../BottomBar/BottomBar";

const MainLayout = (props) => (
	<div className="app-holder app-holder--with-both-bars">
		{props.children}
		<BottomBar/>
	</div>
);
export default MainLayout;
