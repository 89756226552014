import React, {useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import {FuiLoader} from "../common";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
    //return <Zoom in="true" ref={ref} {...props} />;
    //return <Fade in="true" ref={ref} {...props} />;
});

const EditProductForm = props => {
    const [product, setProduct] = useState(props.currentProduct);
    const [error, setError] = useState('');
    const [inputNameError, setInputNameError] = useState('');
    const [loading, setLoading] = useState(false);

    // используем effect-hook
    useEffect(
        () => {
            // вызывай данную функцию
            setProduct(props.currentProduct)
        },
        [props] // всегда, если изменились props
    )

    const handleInputChange = event => {
        const {name, value} = event.target
        if (name==="name" && value) setInputNameError(false);
        setProduct({...product, [name]: value})
    }

    const [open, setOpen] = useState(true);
    const handleSubmit = event => {
        event.preventDefault()
        if (!product.name) {
            setInputNameError({error:"Пустое название продукта"});
            return;
        }
        //обновление на сервере
        setLoading(true);
        let api_root = process.env.REACT_APP_API_ROOT;
        if (1 && process.env.NODE_ENV === "development") {
            api_root = '/fakeapi/';
        }
        let url = api_root+'products';
        if (product.id) {
            url += product.id;
        }
        fetch(url, {
            method: "POST",
            body: JSON.stringify(product)
        })
            .then((res) => res.json())
            .then((json) => {
                //обновление в списке (если есть)
                props.updateProduct(product.id, product)
                //закрыть диалог
                handleClose();
            })
            .catch((e) => {
                setError("Ошибка. Попробуйте позднее");
                console.error(e);
            })
            .finally(() => {
                setLoading(false)
            });
    }
    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };
    const handleDelete = () => {
        let answer = window.confirm('Удалить продукт?')

        if (answer) {
            //TODO удалять из базы данных через API
            setOpen(false);
            props.onClose();
            props.onDelete(product.id);
        }
    };
    return (
        <Dialog fullScreen open={open} onClose={handleClose}
                TransitionComponent={Transition} closeAfterTransition="true">
            <AppBar elevation={2}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Изменить продукт
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="dialog-content">
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="fui-data-form">
                        <TextField name="name" label="Название" variant="outlined"
                                   value={product.name} onChange={handleInputChange}
                                   error={!inputNameError} helperText={inputNameError}
                        />
                        <TextField name="k" label="Калорий, ккал" variant="outlined"
                                   inputProps={{ inputMode: 'numeric' }}
                                   value={product.k} onChange={handleInputChange}/>
                        <TextField name="b" label="Белки, гр" variant="outlined"
                                   inputProps={{ inputMode: 'numeric' }}
                                   value={product.b} onChange={handleInputChange}/>
                        <TextField name="z" label="Жиры, гр" variant="outlined"
                                   inputProps={{ inputMode: 'numeric' }}
                                   value={product.z} onChange={handleInputChange}/>
                        <TextField name="u" label="Углеводы, гр" variant="outlined"
                                   inputProps={{ inputMode: 'numeric' }}
                                   value={product.u} onChange={handleInputChange}/>
                        <TextField name="comment" label="Комментарий" variant="outlined"
                                   value={product.comment} onChange={handleInputChange}/>
                        <div className="fui-drawer-bottom fui-drawer-bottom--2actions">
                            <Button variant="outlined" onClick={handleDelete}>Удалить</Button>
                            <Button variant="contained" type="submit">Сохранить</Button>
                        </div>
                        {error && <Alert severity="error">{error}</Alert>}
                        {loading && <FuiLoader />}
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export {EditProductForm}
