import React from "react";
import {Redirect, Route, withRouter, Link} from "react-router-dom";
import {Button} from "@material-ui/core";

export const fakeAuth = {
    isAuthenticated: false,
    username: '',
    authenticate(cb) {
        this.isAuthenticated = true;
        this.username = "Мари Тестовна";
        localStorage.setItem('isAuthenticated',this.isAuthenticated.toString());
        localStorage.setItem('username',this.username);
        setTimeout(cb, 100); // fake async
    },
    signout(cb) {
        this.isAuthenticated = false;
        this.username = "";
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('username');
        setTimeout(cb, 100); // fake async
    },
    init() {
        //localStorage()
        this.isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
        this.username = this.isAuthenticated ? localStorage.getItem('username') : '';
    }
};

export class Login extends React.Component {
    state = {
        redirectToReferrer: false
    };
    login = () => {
        fakeAuth.authenticate(() => {
            this.setState(() => ({
                redirectToReferrer: true
            }));
        });
    };
    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer === true) {
            return <Redirect to={from} />;
        }

        return (
            <div className="app-page">
                <div className="app-content app-content--place-content-to-center">
                    <div className="login-panel">
                        <div className="login-panel__text">Вам необходимо войти чтобы продолжить пользоваться приложением</div>
                        <Button variant="contained" onClick={this.login}>Войти</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            fakeAuth.isAuthenticated === true ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login/",
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

export const AuthButton = withRouter(({ history }) =>
    fakeAuth.isAuthenticated ? (
        <p>
            Welcome, {fakeAuth.username} !{" "}
            <button
                onClick={() => {
                    fakeAuth.signout(() => history.push("/"));
                }}
            >
                Sign out
            </button>

            <Link to="/user/">Test fire</Link>
        </p>
    ) : (
        <p>Вы не авторизованы.</p>
    )
);