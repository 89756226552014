import React, {useEffect, useState} from "react";
import TopBar from "../../components/common/TopBar/TopBar";
import WaterPanel from "../../components/common/WaterPanel/WaterPanel";
import Article from "../../components/common/Article/Article";
import CorridorPanel from "../../components/common/CorridorPanel/CorridorPanel";
import TodayMealList from "../../components/common/TodayMealList/TodayMealList";
import TodayMealListSkeleton from "../../components/common/TodayMealList/TodayMealListSkeleton";
import FuiFire from "../../components/fire/FuiFire";
import Logo from "../../assets/images/logo.svg"
import ArticleImage from "../../assets/images/article.png"
import CalendarDialog from "../../components/common/CalendarDialog/CalendarDialog";
import WeekCalendar from "../../components/meal/WeekCalendar";

export default function HomePage(props) {
	let [curDate, setCurDate] = useState(new Date().yyyymmdd());
	useEffect(() => {
		if (props.match.params.date && props.match.params.date.length===10) {
			//TODO check date format
			setCurDate(new Date(props.match.params.date).yyyymmdd());
		}else{
			setCurDate(new Date().yyyymmdd());
		}
	}, [props]);

	const waterPars = {
		amount: 1500,
		target: 2500,
	}

	const articleContent = {
		image: ArticleImage,
		title: "Утренний бег",
		text: "Именно с бега утром нужно начинать свой день.",
	}
	//TODO get from day info (server)
	let [dayinfo, setDayinfo] = useState({
		k: 0, b: 0, z: 0, u: 0,
		k1: 0, k2: 0,
		b1: 0, b2: 0,
		z1: 0, z2: 0,
		u1: 0, u2: 0,
		date: '',
		sid: 0
	});

	//TODO move to global user config
	const PartTypesList = [
		"",
		"Завтрак1",
		"Ланч",
		"Обед",
		"Полдник",
		"Ужин",
		"Перекус",
		"Свое",
	]

	let [curMeals, setCurMeals] = useState();
	useEffect(()=> {
		let api_root = process.env.REACT_APP_API_ROOT;
		let url = api_root+'mealstat';
		if (curDate) {
			url += '/'+curDate;
		}
		fetch(url)
			.then((res) => res.json())
			.then((json) => {
				json.mealstats.map(item=>{
					item.part.name = PartTypesList[item.part.mid];
					return item;
				});
				setCurMeals(json.mealstats);
				console.log(json)
			})
			.catch((e) => {
				console.error(e);
			});

		let url_mealday = api_root+'mealday'+'/'+curDate;
		fetch(url_mealday)
			.then((res) => res.json())
			.then((json) => {
				setDayinfo(json.mealday)
			})
			.catch((e) => {
				setError("Ошибка загрузки. Попробуйте позднее");
				console.error(e);
			});
	},[curDate]);
	let [error, setError] = useState();
	return (
		<div className="app-page">
			<TopBar logo={Logo}>
				<CalendarDialog date={curDate} onSelect={setCurDate}/>
			</TopBar>
			<div className="app-content">
				<WeekCalendar date={curDate} onSelect={setCurDate} startFromMonday={false}/>
				<div className="mt-16">
					<WaterPanel date={curDate} pars={waterPars} />
				</div>
				<div className="mt-32">
					<Article content={articleContent} />
				</div>
				<div className="mt-32">
					<CorridorPanel date={curDate} day={dayinfo} total={dayinfo} title="Коридор питания за день" />
				</div>
				{curMeals ? (
					<div className="mt-32">
						<TodayMealList date={curDate} items={curMeals} />
					</div>
				) : !error ? (
					<div className="mt-32">
						<TodayMealListSkeleton />
					</div>
				) : null}
				{false &&
					<div className="mt-32">
						 <FuiFire />
					</div>
				}
			</div>
		</div>
	);
}
