import React from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import OpacityIcon from "@material-ui/icons/Opacity";
import Card from "@material-ui/core/Card";
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import SportsVolleyballOutlinedIcon from '@material-ui/icons/SportsVolleyballOutlined';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import {ReactComponent as WeighingScaleIcon} from "../../../assets/images/weighing.svg";
import {ReactComponent as SlimDownIcon} from "../../../assets/images/slim-down.svg";
import AddIcon from '@material-ui/icons/Add';
import {Link as RouterLink} from "react-router-dom";

function ActionTile(props) {
	const handleClick = props.onOpen
	return (
		<Card className={props.status==="done" ? ("fui-action-tile state-ok") : ("fui-action-tile")}>
			<CardActionArea component={props.link ? (RouterLink) : ("button")} to={props.link} onClick={props.onOpen ? (handleClick) : null}>
				{props.icon==="water" &&(
					<OpacityIcon className="fui-action-tile__icon" />
				)}
				{props.icon==="weight" &&(
					<WeighingScaleIcon className="fui-action-tile__icon" />
				)}
				{props.icon==="volume" &&(
					<SlimDownIcon className="fui-action-tile__icon" />
				)}
				{props.icon==="meal" &&(
					<FastfoodOutlinedIcon className="fui-action-tile__icon" />
				)}
				{props.icon==="sport" &&(
					<SportsVolleyballOutlinedIcon className="fui-action-tile__icon" />
				)}
				{props.icon==="calc" &&(
					<ExposureOutlinedIcon className="fui-action-tile__icon" />
				)}
				{props.icon==="comment" &&(
					<ModeCommentOutlinedIcon className="fui-action-tile__icon" />
				)}
				<div className="fui-action-tile__mark">
					{props.status==="done" ? (
						<DoneOutlinedIcon />
					) : (
						<AddIcon />
					)}
				</div>
				<div className="fui-action-tile__name">{props.title}</div>
				{props.status==="done" ? (
					props.text ? (
						<div className="fui-action-tile__desc">{props.text}</div>
						) : (
						<div className="fui-action-tile__desc">Заполнено</div>
					)
				) : (
					<div className="fui-action-tile__desc">Добавить</div>
				)}
			</CardActionArea>
		</Card>
	)
}
export default ActionTile;