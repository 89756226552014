import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea'

function Article(props) {
	return (
		<Card className="fui-article">
			<CardActionArea>
				<CardMedia image={props.content.image} />
				<CardContent>
					<Typography gutterBottom variant="h6" component="h2">{props.content.title}</Typography>
					<Typography variant="body2" color="textSecondary" component="p">{props.content.text}</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}
export default Article;