import React, {useEffect, useState} from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from "@material-ui/lab/Alert";
import {FuiLoader} from "../common";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';

const AddMealPart = props => {
    const initialFormState = {
        id: null,
        date: '',
        mid: '',
        time: '',
        k: 0, b: 0, z: 0, u: 0, w: 0
    }
    const [item, setItem] = useState(initialFormState);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(true);

    const submitPart = (item) => {
        //обновление на сервере
        setShowTypeControl(false);
        setLoading(true);
        let api_root = process.env.REACT_APP_API_ROOT;
        let url = api_root+'mealpart';
        if (item.id) {
            url += item.id;
        }
        fetch(url, {
            method: "POST",
            body: JSON.stringify(item)
        })
            .then((res) => res.json())
            .then((json) => {
                // вызываем addProduct из хука из App
                props.onSelect(json.mealpart,!item.id)
                // обнуляем форму, с помощью setProduct функции
                // которая у нас взята из хука в данном компоненте [1]
                //setItem(initialFormState)
                //закрыть диалог
                handleClose();
            })
            .catch((e) => {
                setError("Ошибка. Попробуйте позднее");
                console.error(e);
            })
            .finally(() => {
                setLoading(false)
            });
    }
    const handleClose = () => {
        console.log('handleClose');
        setOpen(false);
    };

	const handleSelect = id => {
	    setItem({...item,mid:id});
        setShowTypeControl(false);
        setShowDateControl(true);
	}

    const [selectedDate, setSelectedDate] = useState(props.date);
    const handleDateChange = (date) => {
        console.log('handleDateChange',date);
        //setSelectedDate(date);
        let dt = new Date(date);
        setItem({
            ...item,
            time:dt.toTimeString().substring(0,5),
            date:dt.yyyymmdd()
        });
    };
    const handleDateClose = () => {
        console.log('handleDateClose');
        setShowTypeControl(true);
        setShowDateControl(false);
    }

    useEffect(()=>{
        if (item.time) {
            submitPart(item);
            setShowDateControl(false);
        }
    },[item])

    //передаем наверх статус закрытия
    useEffect(()=>{
        if (!open) {
            console.log('fire onClose');
            props.onClose();
        }
    },[open]);

    /*
    const handleSelectTime = (date) => {
        console.log('handleSelectTime',date);
    };
    */

    const [showDateControl, setShowDateControl] = useState(false);
    const [showTypeControl, setShowTypeControl] = useState(true);

    useEffect(()=>{
        console.log('add part open',item);
        setItem(initialFormState);
        return ()=>{
            console.log('unmount');
        };
    },[]);

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className="fui-dialog-min-size">
            <DialogTitle id="simple-dialog-title">
                {showTypeControl && (
                    <span>Выберите прием пищи {props.date}</span>
                )}
            </DialogTitle>
            {showTypeControl && (
            <List component="nav" aria-label="Выберите период питания">
                {props.tpname.map((item,index) => (
                    item && (
                        <ListItem button onClick={() => handleSelect(index)} key={index}>
                            <ListItemText primary={item} />
                        </ListItem>
                    )
                ))}
            </List>
            )}

            {showDateControl && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        name="time"
                        label=""
                        ampm={false}
                        variant="dialog"
                        value={selectedDate}
                        onChange={handleDateChange}
                        onClose={handleDateClose}
                        open="true"
                    />
                </MuiPickersUtilsProvider>
            )}

            {error && <Alert severity="error">{error}</Alert>}
            {loading && <FuiLoader />}
        </Dialog>
    )
}

export { AddMealPart }
